const Availability = {
  AVAILABLE: {
    key: "AVAILABLE",
    label: "Available in stock",
    label_fr: "Disponible en stock",
  },
  PRE_ORDER: {
    key: "PRE_ORDER",
    label: "On pre-order",
    label_fr: "Sur pré-commande",
  },
  BESPOKE: {
    key: "BESPOKE",
    label: "Bespoke piece",
    label_fr: "Pièce sur-mesure",
  },
  UNIQUE: {
    key: "UNIQUE",
    label: "Unique piece sold out",
    label_fr: "Pièce unique plus en vente",
  },
};

/*
static getAvailabilityTooltip(availability) {
    if (availability === 0) {
      if (language.key === "FR") {
        return "Cette création est disponible en édition limitée.";
      }
      return "This creation is available in limited edition.";
    } else if (availability === 1) {
      if (language.key === "FR") {
        return "Une précommande est la réservation d'un article qui n'est pas encore disponible à la vente mais qui le sera bientôt. La date de livraison estimée est indiquée pour chaque article en précommande sur la page du produit.";
      }
      return "A pre-order is the reservation of an item that is not yet available for sale but will be soon. The estimated delivery date is indicated for each pre-order item within the product page.";
    } else if (availability === 2) {
      if (language.key === "FR") {
        return "Nous fournissons des pièces sur mesure. Elle sera réalisée sur commande en 30 jours ouvrables. Les articles créés sur commande ne peuvent pas être retournés, il n'y a donc pas de remboursement.";
      }
      return "We provide Made To Measurement bespoke piece. It will be made to order in 30 working days. The items created to order are not subject to return therefore there is no refund.";
    } else if (availability === 3) {
      if (language.key === "FR") {
        return "Cette pièce a été créée sur demande pour un client VIP. Nous ne créerons pas de pièce similaire.";
      }
      return "This piece was created on request for a VIP client. We will not create a similar piece.";
    }
    return "";
  }
*/

export default Availability;
