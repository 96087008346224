import React from "react";
import Page from "component/commons/page/Page";
import { Outlet } from "react-router-dom";
import headerTexts from "translation/texts/header-texts";
import useTranslate from "hooks/useTranslate";

export default function CreationPage() {
  const translate = useTranslate();
  return (
    <Page
      title={translate(headerTexts.CREATIONS)}
      endpoint="creations"
      variant="lightBackground"
    >
      <Outlet />
    </Page>
  );
}
