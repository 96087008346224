import { styled } from "@mui/material";

const Tag = styled("div")(({theme, selected}) => ({
  padding: "0.4em",
  borderRadius: 5,
  backgroundColor: selected ? theme.palette.action.active : theme.palette.background.primary,
  "&:hover": {
    backgroundColor: theme.palette.action.active,
    cursor: selected ? "initial": "pointer",
  },
}))

export default Tag;
