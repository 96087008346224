export default {
  ALL_RIGHTS_RESERVED: {
    EN: "All rights reserved",
    FR: "Tout droits reservés",
  },
  COMPANY: {
    EN: "Company",
    FR: "Société",
  },
  CONTACT: {
    EN: "Contact",
    FR: "Contact",
  },
  LEGAL: {
    EN: "Legal",
    FR: "Législation",
  },
  NEWS: {
    EN: "News",
    FR: "Actualités",
  },
};
