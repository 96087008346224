const { styled } = require("@mui/material");

const CoverVideo = styled("video")(
  () => `
width: 100%;
max-height: 90vh;
object-fit: cover;
object-position: center;
overflow: hidden;
`
);

export default CoverVideo;
