export default function picturesUpload(apiCall, uploads, dataLabel, endpoint) {
  let formData = new FormData();
  for (let i = 0; i < uploads.length; ++i) {
    formData.append(dataLabel, uploads[i], uploads[i].name);
  }

  return apiCall({
    method: "POST",
    url: `/upload/${endpoint}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}