import React from "react";
import PageHeader from "./PageHeader";
import PageBody from "./PageBody";

export default function Page({ title, endpoint, children, variant }) {
  return (
    <>
      <PageHeader title={title} endpoint={endpoint} />
      <PageBody variant={variant}>{children}</PageBody>
    </>
  );
}
