const { styled } = require("@mui/material");

const SimpleButton = styled("button")(({theme}) => `
  color: ${theme.palette.text.primary};
  background: transparent;
  border: none;

  &:hover {
    color: ${theme.palette.action.active};
    cursor: pointer;
  }
`)

export default SimpleButton;
