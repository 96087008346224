import React, { useState } from "react";
import { Search, Close } from "@mui/icons-material";
import { styled } from "@mui/material";

const SearchContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
  padding: 1,
}));

const SearchInput = styled("input")(({ theme }) => ({
  fontFamily: "Arial",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  border: "none",
  WebkitAppearance: "none",
  outline: "none",
  verticalAlign: "middle",
}));

const CloseIcon = styled(Close)(({ theme }) => ({
  opacity: 0.5,
  verticalAlign: "middle",
  "&:hover": {
    color: theme.palette.action.active,
    opacity: 1,
    cursor: "pointer",
  },
}));

const SearchIcon = styled(Search)(({ theme }) => ({
  verticalAlign: "middle",
  "&:hover": {
    color: theme.palette.action.active,
    cursor: "pointer",
  },
}));

export default function SearchBox({ onSubmit, text }) {
  const [value, setValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit(value);
    }
  };

  const reset = () => {
    setValue("");
    onSubmit("");
  };

  return (
    <SearchContainer>
      <SearchInput
        type="text"
        placeholder={text}
        size={25}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <CloseIcon
        style={{ visibility: value === "" ? "hidden" : "visible" }}
        onClick={reset}
      />
      <SearchIcon />
    </SearchContainer>
  );
}
