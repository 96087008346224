import { useLocation } from "react-router-dom";

export default function useBackHistory() {
  const location = useLocation();

  return {
    state: {
      goBackEnabled: true,
      previousRoute: location,
    },
  };
}