import { Button, styled } from "@mui/material";

const isEnabled = (enabled) => {
  return enabled === undefined || enabled === "true";
};

const getSize = (size) => {
  return size !== undefined ? size : "initial";
};

const ActionButton = styled(Button)(({ theme, enabled, size }) => ({
  padding: "0.5em",
  background: "transparent",
  borderRadius: 3,
  fontSize: getSize(size),
  color: isEnabled(enabled) ? theme.palette.action.active : theme.palette.text.secondary,
  border: `1px solid ${isEnabled(enabled) ? theme.palette.action.active : theme.palette.text.secondary}`,
  "&:hover": {
    backgroundColor: isEnabled(enabled) ? theme.palette.action.active : undefined,
    color: isEnabled(enabled) ? theme.palette.background.secondary : undefined,
    border: isEnabled(enabled) ? `1px solid ${theme.palette.text.secondary}` : undefined,
    cursor: isEnabled(enabled) ? "pointer" : undefined,
  },
}));

export default ActionButton;
