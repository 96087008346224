import { Checkbox } from '@mui/material';
import { FormGroup, FormControlLabel, Grid } from "@mui/material";
import React from "react"

export default function AdminCheckbox({data, value, setValue}) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => setValue(data.key, e.target.checked)}
            checked={value}
            value={value}
          />
        }
        label={data.label}
      />
      </FormGroup>
    </Grid>
  );
}