import AddButton from "component/commons/buttons/AddButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";
import { INSERT_ID } from "utils/constants";
import AdminTable from "../commons/AdminTable";
import useBackHistory from "hooks/useBackHistory";
import Toaster, { useToaster } from "component/commons/Toaster";

const TABLE_HEADERS = ["ID", "Name"];

export default function CategoryTable() {
  const navigate = useNavigate(); 
  const backHistory = useBackHistory()
  const sessionToken = useRecoilValue(sessionTokenState);

  const [categoryTableData, setCategoryTableData] = useState([]);
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster()

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    API.get("/category")
      .then(function (response) {
        let tableData = []
        response.data.forEach(category => tableData.push({
          id: category.id,
          cells: [category.id, category.name],
        }))
        setCategoryTableData(tableData);
      })
      .catch(function (error) {
        openToaster(error.toString())
        console.error(error);
      });
  };

  const editCategory = (categoryId) => {
    navigate(encodeURI(categoryId), {...backHistory})
  }

  const deleteCategory = (categoryId) => {
    API({
      method: "DELETE",
      url: `/category/${categoryId}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function () {
        setCategoryTableData(categoryTableData.filter(category => category.id !== categoryId))
      })
      .catch(function (error) {
        openToaster(error.toString())
        console.error(error);
      });
  };

  return (
    <>
      <AdminTable
        name="category"
        headers={TABLE_HEADERS}
        rows={categoryTableData}
        editAction={editCategory}
        deleteAction={deleteCategory}
      />
      <AddButton onClick={() => editCategory(INSERT_ID)} />
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}