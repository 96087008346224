import { useAuth0 } from "@auth0/auth0-react";
import config from "config";

export default function useAccessToken({ scope }) {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const options = {
    audience: `https://${config.auth0Domain}/api/v2/`,
    scope: scope,
  };

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently(options);
    } catch (error) {
      if (error.error === "consent_required") {
        return await getAccessTokenWithPopup(options);
      } else {
        throw error;
      }
    }
  };

  return { getAccessToken };
}
