const { styled } = require("@mui/material");

const CoverImage = styled("img")(
  () => `
  width: 100%;
  max-height: 90vh;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
`
);

export default CoverImage;
