import React from "react";
import AppBar from "@mui/material/AppBar";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import MenuButton from "component/commons/buttons/MenuButton";
import Logo from "component/commons/Logo";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AppbarIcons } from "./AppbarIcons";
import MainMenu from "./MainMenu";
import "style/override-drawers.scss";
import BasketMenu from "./BasketMenu";
import { Drawer } from "@mui/material";
import LoginMenu from "./LoginMenu";
import { APP_BAR_HEIGHT } from "utils/constants";

const APP_BAR_Z_INDEX = 2000;
const BASKET_DRAWER = "basket";
const MENU_DRAWER = "menu";
const LOGIN_DRAWER = "login";

export default function Navigator() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (drawer) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(drawer);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "background.primary",
          height: APP_BAR_HEIGHT,
          zIndex: APP_BAR_Z_INDEX,
        }}
      >
        <Toolbar
          style={{
            minHeight: APP_BAR_HEIGHT,
            position: "relative",
          }}
        >
          <MenuButton
            checked={drawerOpen === MENU_DRAWER}
            handleClick={toggleDrawer(
              drawerOpen === MENU_DRAWER ? false : MENU_DRAWER
            )}
          />
          <Link
            to="/"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0)",
              alignSelf: "center",
              "& > img": {
                verticalAlign: "middle",
              },
            }}
          >
            <Logo size={30} />
          </Link>
          <AppbarIcons
            handleProfileClick={toggleDrawer(
              drawerOpen === LOGIN_DRAWER ? false : LOGIN_DRAWER
            )}
            handleBasketClick={toggleDrawer(
              drawerOpen === BASKET_DRAWER ? false : BASKET_DRAWER
            )}
          />
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen === MENU_DRAWER}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(MENU_DRAWER)}
      >
        <MainMenu onClose={toggleDrawer(false)} topMargin={APP_BAR_HEIGHT} />
      </SwipeableDrawer>
      <Drawer
        anchor="right"
        open={drawerOpen === BASKET_DRAWER}
        onClose={toggleDrawer(false)}
      >
        <BasketMenu onClose={toggleDrawer(false)} topMargin={APP_BAR_HEIGHT} />
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerOpen === LOGIN_DRAWER}
        onClose={toggleDrawer(false)}
      >
        <LoginMenu onClose={toggleDrawer(false)} topMargin={APP_BAR_HEIGHT} />
      </Drawer>
    </>
  );
}
