import useBackHistory from "hooks/useBackHistory";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INSERT_ID } from "utils/constants";
import AdminEditForm from "../commons/form/AdminEditForm";
import AdminFormBuilder from "../commons/form/AdminFormBuilder";
import AdminFormType from "../commons/form/AdminFormType";
import useBackendService from "hooks/useBackendService";
import Toaster, { useToaster } from "component/commons/Toaster";
import LoadingPage from "component/pages/LoadingPage";
import { getUpdatedCoverPath } from "../commons/utilities";
import Availability from "models/Availability";
import picturesUpload from "../commons/pictures-upload";
import handleError from "../commons/handle-error";

export default function CustomSectionEdit() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [creation, setCreation] = useState({});
  const [creationFormData, setCreationFormData] = useState(buildForm({}, [], []));

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  useEffect(() => {
    if (productId && productId !== INSERT_ID) {
      getCreation();
    }
  }, [productId]);

  useEffect(() => {
    setCreationFormData(buildForm(creation, categories, collections, deletePicture));
  }, [creation, categories, collections]);

  const getCategories = () => {
    apiCall({
      method: "GET",
      url: "/category",
    })
      .then(function (response) {
        setCategories(response.data);
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const getCollections = () => {
    apiCall({
      method: "GET",
      url: "/collection",
    })
      .then(function (response) {
        setCollections(response.data);
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const getCreation = () => {
    apiCall({
      method: "GET",
      url: `/product/${productId}`,
      params: {
        displayAdminFields: true,
      },
    })
      .then((response) => {
        setCreation(response.data);
      })
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const updateCreation = (formValues) => {
    const { cover_path, previous_picture } = getUpdatedCoverPath(
      formValues.cover.uploads,
      creation.first_pic
    );
    return apiCall({
      method: productId === INSERT_ID ? "POST" : "PUT",
      url: "/product",
      data: {
        id: productId,
        name: formValues.name,
        category_name: formValues.category || null,
        collect_id: formValues.collection || null,
        first_pic: cover_path,
        price: formValues.price,
        price_updated: formValues.price !== creation.price,
        male: formValues.gender,
        size: formValues.size,
        availability: formValues.availability,
        visible: formValues.visible ? 1 : 0,
        previous_picture,
        description: formValues.description,
        description_fr: formValues.description_fr,
      },
    });
  };

  const deletePicture = (type, path) => {
    const routes = path.split("/");
    const fileName = routes[routes.length - 1];

    apiCall({
      method: "DELETE",
      url: `/upload/product/${productId}/${type}`,
      data: {
        fileName,
      },
    })
      .then(() => getCreation())
      .catch((error) => handleError(openToaster, error));
  };

  const submitForm = async (formValues) => {
    try {
      let id = productId;
      const response = await updateCreation(formValues);
      if (productId === INSERT_ID) {
        id = response.data.insertId;
      }

      const promises = [];
      if (formValues.cover.uploads?.length) {
        promises.push(
          picturesUpload(apiCall, formValues.cover.uploads, "product_cover", `product/${id}/cover`)
        );
      }
      if (formValues.pics.uploads?.length) {
        promises.push(
          picturesUpload(apiCall, formValues.pics.uploads, "product_pictures", `product/${id}/pics`)
        );
      }
      if (formValues.colors.uploads?.length) {
        promises.push(
          picturesUpload(apiCall, formValues.colors.uploads, "product_colors", `product/${id}/colors`)
        );
      }
      Promise.all(promises)
        .then(() => {
          if (productId === INSERT_ID) {
            navigate("../" + id, { replace: true, ...backHistory });
          } else {
            getCreation();
          }
        })
        .catch((error) => handleError(openToaster, error));
    } catch (error) {
      handleError(openToaster, error);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <AdminEditForm formData={creationFormData} onSubmit={submitForm} />
      )}
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}

function buildForm(creation, categories, collections, onDelete) {
  let form = [];

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("name")
      .withLabel("Name")
      .withInitialValue(creation.name)
      .withDefaultValue("")
      .withMaxLength(48)
      .makeMandatory()
      .build()
  );

  let categoryFormBuilder = new AdminFormBuilder(AdminFormType.SELECT_MENU)
    .withKey("category")
    .withLabel("Category")
    .withInitialValue(creation.category_name)
    .withDefaultValue("");
  categories.forEach((category) => categoryFormBuilder.addItem(category.name, category.name));
  form.push(categoryFormBuilder.build());

  let collectionFormBuilder = new AdminFormBuilder(AdminFormType.SELECT_MENU)
    .withKey("collection")
    .withLabel("Collection")
    .withInitialValue(creation.collect_id)
    .withDefaultValue("");
  collections.forEach((collect) => collectionFormBuilder.addItem(collect.id, collect.name));
  form.push(collectionFormBuilder.build());

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("price")
      .withLabel("Price EUR (€)")
      .withInitialValue(creation.price)
      .withDefaultValue("")
      .makeNumber()
      .makeMandatory()
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("size")
      .withLabel("Size")
      .withInitialValue(creation.size)
      .withDefaultValue("")
      .makeMultiline()
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.CHECKBOX)
      .withKey("visible")
      .withLabel("Display")
      .withInitialValue(Boolean(creation.visible))
      .withDefaultValue(false)
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.SELECT_MENU)
      .withKey("gender")
      .withLabel("Gender")
      .withInitialValue(creation.gender)
      .withDefaultValue("")
      .addItem("", "")
      .addItem("male", "male")
      .addItem("female", "female")
      .build()
  );

  let availabilityFb = new AdminFormBuilder(AdminFormType.SELECT_MENU)
    .withKey("availability")
    .withLabel("Availability")
    .withInitialValue(creation.availability)
    .withDefaultValue(Availability.BESPOKE.key);
  Object.keys(Availability).forEach((availability) =>
    availabilityFb.addItem(availability, Availability[availability].label)
  );
  form.push(availabilityFb.build());

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_AREA)
      .withKey("description")
      .withLabel("Description")
      .withInitialValue(creation.description)
      .withDefaultValue("")
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_AREA)
      .withKey("description_fr")
      .withLabel("Description FR")
      .withInitialValue(creation.description_fr)
      .withDefaultValue("")
      .build()
  );

  let cover = creation.first_pic ? [creation.first_pic] : [];
  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("cover")
      .withLabel("Cover Picture")
      .withFolder("products/" + creation.id)
      .withInitialValue({ pictures: cover })
      .withDefaultValue({})
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("pics")
      .withLabel("Additional pictures")
      .withFolder(`products/${creation.id}/pics`)
      .withInitialValue({ pictures: creation.pics })
      .withDefaultValue({})
      .withMultiple()
      .withOnDelete((path) => onDelete("pics", path))
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("colors")
      .withLabel("Colors pictures")
      .withFolder(`products/${creation.id}/colors`)
      .withInitialValue({ pictures: creation.colors })
      .withDefaultValue({})
      .withMultiple()
      .withOnDelete((path) => onDelete("colors", path))
      .build()
  );

  return form;
}
