import AddButton from "component/commons/buttons/AddButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INSERT_ID } from "utils/constants";
import AdminTable from "../commons/AdminTable";
import useBackHistory from "hooks/useBackHistory";
import Toaster, { useToaster } from "component/commons/Toaster";
import useBackendService from "hooks/useBackendService";
import { bindPicturePath, displayPrice } from "utils/tools";
import useQuery from "hooks/useQuery";
import CreationFilters, { filters } from "component/creations/CreationFilters";
import handleError from "../commons/handle-error";

const TABLE_HEADERS = ["ID", "Cover", "Name", "Category", "Collection", "Price"];

export default function CreationTable() {
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const query = useQuery();

  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [creations, setCreations] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [orderBy, setOrderby] = useState();
  const [doneFetching, setDoneFetching] = useState(false);
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  useEffect(() => {
    reload();
  }, [query]);

  useEffect(() => {
    const order = query.get("order");
    const doReset = order !== orderBy;
    if (doReset) {
      getCreations(doReset ? 0 : creations.length);
    } else {
      applyFilters(creations);
    }
  }, [query, filterText]);

  const reload = () => {
    setDoneFetching(false);
    getCreations(0);
  };

  const getCategories = () => {
    apiCall({
      method: "GET",
      url: "/category",
    })
      .then(function (response) {
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getCollections = () => {
    apiCall({
      method: "GET",
      url: "/collection",
    })
      .then(function (response) {
        setCollections(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getCreations = (offset) => {
    const order = query.get("order");
    apiCall({
      method: "GET",
      url: "/product",
      params: {
        displayAdminFields: true,
        offset,
        order,
      },
    })
      .then((response) => {
        let creationsTmp;
        if (offset === 0) {
          creationsTmp = [];
        } else {
          creationsTmp = [...creations];
        }
        creationsTmp = creationsTmp.concat(response.data);
        setCreations(creationsTmp);
        applyFilters(creationsTmp);
        if (response.data.length === 0) {
          setDoneFetching(true);
        }
        setOrderby(order);
      })
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const applyFilters = (creations) => {
    const categoryFilter = query.get("category");
    const collectionFilter = query.get("collection");
    const genderFilter = query.get("gender");
    let tableData = [];
    filters(creations, categoryFilter, collectionFilter, genderFilter, filterText).forEach(
      (creation) =>
        tableData.push({
          id: creation.id,
          cells: [
            creation.id,
            Boolean(creation.first_pic) &&
              bindPicturePath("products", creation.id, creation.first_pic),
            creation.name,
            creation.category_name,
            creation.collection_name,
            displayPrice(creation.price),
          ],
        })
    );
    setFilteredTableData(tableData);
  };

  const editProduct = (creationId) => {
    navigate(encodeURI(creationId), { ...backHistory });
  };

  const redirectCreation = (creationId) => {
    return `/creations/${creationId}`;
  };

  const deleteProduct = (creationId) => {
    apiCall({
      method: "DELETE",
      url: `/product/${creationId}`,
    })
      .then(() => {
        let tmpData = [...creations];
        let tmpFilteredData = [...filteredTableData];
        setCreations(tmpData.filter((creation) => creation.id !== creationId));
        setFilteredTableData(tmpFilteredData.filter((creation) => creation.id !== creationId));
        reload();
      })
      .catch((error) => handleError(openToaster, error));
  };

  const loadMoreProperties = {
    doneFetching,
    isLoading,
    hasResults: Boolean(creations.length),
    callback: () => getCreations(creations.length),
  };

  return (
    <>
      <CreationFilters categories={categories} collections={collections} onSearch={setFilterText} />
      <AdminTable
        name="creation"
        headers={TABLE_HEADERS}
        rows={filteredTableData}
        redirectAction={redirectCreation}
        editAction={editProduct}
        deleteAction={deleteProduct}
        loadMoreProperties={loadMoreProperties}
      />
      <AddButton onClick={() => editProduct(INSERT_ID)} />
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}
