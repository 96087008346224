import { Grid, styled } from "@mui/material";
import TooltipButton from "component/commons/buttons/TooltipButton";
import React, { useEffect, useState } from "react";

const FormContainer = styled("div")(() => ({
  marginTop: "1rem",
  padding: "2rem",
  borderRadius: 5,
}));

export default function AdminEditForm({ formData, onSubmit }) {
  const [state, setState] = useState({});

  useEffect(() => {
    setState(getInitialState(formData));
  }, [formData]);

  const setValue = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleFormSubmit = () => {
    onSubmit(state)
  }

  const getButtonTooltip = () => {
    let missingInputs = [];
    formData.forEach((formElement) => {
      if (
        formElement.mandatory &&
        (state[formElement.key] === undefined ||
          state[formElement.key] === null ||
          state[formElement.key] === "")
      ) {
        missingInputs.push(formElement.label);
      }
    });
    if (missingInputs.length) {
      return "The following fields are mandatory: " + missingInputs.join(", ");
    } else {
      return null;
    }
  };

  return (
    <FormContainer>
      <Grid container>
        {formData.map(
          (formElement) =>
            state[formElement.key] !== undefined && (
              <formElement.Type
                key={formElement.key}
                data={formElement}
                value={state[formElement.key]}
                setValue={setValue}
              />
            )
        )}
      </Grid>
      <br />
      <TooltipButton tooltip={getButtonTooltip()} onClick={handleFormSubmit}>
        Submit
      </TooltipButton>
    </FormContainer>
  );
}

function getInitialState(formData) {
  let formValues = {};
  formData.forEach((formElement) => {
    if (formElement.initialValue) {
      formValues[formElement.key] = formElement.initialValue;
    } else {
      formValues[formElement.key] = formElement.defaultValue;
    }
  });
  return formValues;
}
