import {
  ChevronLeft,
  ChevronRight,
  Pause,
  PlayArrow,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import React from "react";
import ImageGallery from "react-image-gallery";
import "style/override-image-gallery.scss";
import { bindPicturePath } from "utils/tools";

const ARROWS_SIZE = "3rem";
const PLAY_AND_ZOOM_SIZE = "2rem"

export default function ProductCarousel({ productId, pics }) {
  const buildImageGalleryPics = () => {
    let images = [];
    for (let i = 0; i < pics.length; ++i) {
      let full_path = bindPicturePath("products", productId, "pics", pics[i]);
      images.push({
        original: full_path,
        thumbnail: full_path,
        originalClass: "image-gallery-custom-picture"
      });
    }
    return images;
  };

  return (
    <div>
      <ImageGallery
        items={buildImageGalleryPics()}
        showIndex
        showPlayButton={pics.length > 1}
        thumbnailPosition="left"
        onErrorImageURL="/logo-made_in_paris.png"
        renderLeftNav={(onClick, disabled) => (
          <button
            className="image-gallery-icon image-gallery-left-nav"
            onClick={onClick}
            disabled={disabled}
          >
            <ChevronLeft style={{ fontSize: ARROWS_SIZE }} />
          </button>
        )}
        renderRightNav={(onClick, disabled) => (
          <button
            className="image-gallery-icon image-gallery-right-nav"
            onClick={onClick}
            disabled={disabled}
          >
            <ChevronRight style={{ fontSize: ARROWS_SIZE }} />
          </button>
        )}
        renderPlayPauseButton={(onClick, isPlaying) => (
          <button
            className="image-gallery-icon image-gallery-play-button"
            onClick={onClick}
          >
            {isPlaying ? (
              <Pause style={{ fontSize: PLAY_AND_ZOOM_SIZE }} />
            ) : (
              <PlayArrow style={{ fontSize: PLAY_AND_ZOOM_SIZE }} />
            )}
          </button>
        )}
        renderFullscreenButton={(onClick, isFullscreen) => (
          <button
            className="image-gallery-icon image-gallery-fullscreen-button"
            onClick={onClick}
          >
            {isFullscreen ? (
              <ZoomOut style={{ fontSize: PLAY_AND_ZOOM_SIZE }} />
            ) : (
              <ZoomIn style={{ fontSize: PLAY_AND_ZOOM_SIZE }} />
            )}
          </button>
        )}
      />
    </div>
  );
}
