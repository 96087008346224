import {
  Accessibility,
  CollectionsBookmark,
  Grade,
  Group,
  LibraryBooks,
  ShoppingBasket,
  Toc,
} from "@mui/icons-material";
import CategoryEdit from "component/administration/edition/CategoryEdit";
import CategoryTable from "component/administration/overview/CategoryTable";
import CollectionEdit from "component/administration/edition/CollectionEdit";
import CollectionTable  from "component/administration/overview/CollectionTable";
import CreationEdit from "component/administration/edition/CreationEdit";
import CreationTable from "component/administration/overview/CreationTable";
import CustomSectionEdit from "component/administration/edition/CustomSectionEdit";
import CustomSectionTable from "component/administration/overview/CustomSectionTable";
import PromotedItemEdit from "component/administration/edition/PromotedItemEdit";
import PromotedItemsTable  from "component/administration/overview/PromotedItemsTable";
import UserTable from "component/administration/overview/UserTable";
import OrderTable from "component/administration/overview/OrderTable";
import Roles from "models/Roles";

const ICON_SIZE = 50;

export default [
  {
    path: "creations",
    authorization: [Roles.ADMIN, Roles.OWNER],
    icon: <Accessibility style={{ fontSize: ICON_SIZE }} />,
    text: "Creations",
    children: [
      {
        path: "",
        element: <CreationTable />,
        authorization: [Roles.OWNER],
      },
      {
        path: ":productId",
        element: <CreationEdit />,
        authorization: [Roles.OWNER],
      },
    ],
  },
  {
    path: "categories",
    authorization: [Roles.ADMIN, Roles.OWNER],
    icon: <Toc style={{ fontSize: ICON_SIZE }} />,
    text: "Categories",
    children: [
      {
        path: "",
        element: <CategoryTable />,
        authorization: [Roles.OWNER],
      },
      {
        path: ":categoryId",
        element: <CategoryEdit />,
        authorization: [Roles.OWNER],
      },
    ],
  },
  {
    path: "promoted-items",
    authorization: [Roles.ADMIN, Roles.OWNER],
    icon: <Grade style={{ fontSize: ICON_SIZE }} />,
    text: "Covers",
    children: [
      {
        path: "",
        element: <PromotedItemsTable />,
        authorization: [Roles.ADMIN, Roles.OWNER],
      },
      {
        path: ":itemId",
        element: <PromotedItemEdit />,
        authorization: [Roles.ADMIN, Roles.OWNER],
      },
    ],
  },
  {
    path: "collections",
    authorization: [Roles.ADMIN, Roles.OWNER],
    icon: <CollectionsBookmark style={{ fontSize: ICON_SIZE }} />,
    text: "Collections",
    children: [
      {
        path: "",
        element: <CollectionTable />,
        authorization: [Roles.OWNER],
      },
      {
        path: ":collectionId",
        element: <CollectionEdit />,
        authorization: [Roles.OWNER],
      },
    ],
  },
  {
    path: "custom-sections",
    authorization: [Roles.ADMIN, Roles.OWNER],
    icon: <LibraryBooks style={{ fontSize: ICON_SIZE }} />,
    text: "Custom Sections",
    children: [
      {
        path: "",
        element: <CustomSectionTable />,
        authorization: [Roles.OWNER],
      },
      {
        path: ":customSectionId",
        element: <CustomSectionEdit />,
        authorization: [Roles.OWNER],
      },
    ],
  },
  {
    path: "orders",
    authorization: [Roles.OWNER],
    icon: <ShoppingBasket style={{ fontSize: ICON_SIZE }} />,
    text: "Orders",
    children: [
      {
        path: "",
        element: <OrderTable />,
        authorization: [Roles.OWNER],
      },
    ],
  },
  {
    path: "users",
    authorization: [Roles.OWNER],
    icon: <Group style={{ fontSize: ICON_SIZE }} />,
    text: "Users",
    children: [
      {
        path: "",
        element: <UserTable />,
        authorization: [Roles.OWNER],
      },
    ],
  },
];
