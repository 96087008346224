import useBackHistory from "hooks/useBackHistory";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INSERT_ID } from "utils/constants";
import AdminEditForm from "../commons/form/AdminEditForm";
import AdminFormBuilder from "../commons/form/AdminFormBuilder";
import AdminFormType from "../commons/form/AdminFormType";
import useBackendService from "hooks/useBackendService";
import Toaster, { useToaster } from "component/commons/Toaster";
import CustomSection from "models/CustomSection";
import LoadingPage from "component/pages/LoadingPage";
import { getUpdatedCoverPath } from "../commons/utilities";

export default function CustomSectionEdit() {
  const { customSectionId } = useParams();
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  const [coverPath, setCoverPath] = useState(null);
  const [customSectionFormData, setCustomSectionFormData] = useState(buildForm({}));

  useEffect(() => {
    if (customSectionId && customSectionId !== INSERT_ID) {
      getCustomSection();
    }
  }, [customSectionId]);

  const getCustomSection = () => {
    apiCall({
      method: "GET",
      url: `/custom-section/${customSectionId}`,
    })
      .then((response) => {
        setCoverPath(response.data.cover_path);
        setCustomSectionFormData(buildForm(response.data, deletePicture));
      })
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const updateCustomSection = (formValues) => {
    const { cover_path, previous_picture } = getUpdatedCoverPath(
      formValues.cover.uploads,
      coverPath
    );
    return apiCall({
      method: customSectionId === INSERT_ID ? "POST" : "PUT",
      url: "/custom-section",
      data: {
        id: customSectionId,
        type: formValues.type,
        name: formValues.name,
        link: formValues.link,
        description: formValues.description,
        description_fr: formValues.description_fr,
        position: 0, // TODO
        cover_path,
        previous_picture,
      },
    });
  };

  const uploadCover = (cover) => {
    let data = new FormData();
    data.append("section_cover", cover, cover.name);
    return apiCall({
      method: "POST",
      url: `/upload/custom-section/cover`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });
  };

  const uploadMedias = (id, medias) => {
    let data = new FormData();
    for (let i = 0; i < medias.length; ++i) {
      data.append("section_pictures", medias[i], medias[i].name);
    }
    return apiCall({
      method: "POST",
      url: `/upload/custom-section/${id}/medias`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });
  };

  const deletePicture = (path) => {
    const routes = path.split("/");
    const fileName = routes[routes.length - 1];

    apiCall({
      method: "DELETE",
      url: `/upload/custom-section/${customSectionId}`,
      data: {
        fileName,
      },
    })
      .then(() => getCustomSection())
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const submitForm = async (formValues) => {
    if (customSectionId === INSERT_ID) {
      try {
        // async: parallelize update and upload
        let promises = [updateCustomSection(formValues)];
        if (formValues.cover.uploads?.length) {
          promises.push(uploadCover(formValues.cover.uploads[0]));
        }
        Promise.all(promises)
          .then(async (values) => {
            const { insertId } = values[0].data;
            if (formValues.medias.uploads?.length) {
              try {
                // sync: wait for insert ID to upload medias
                await uploadMedias(insertId || customSectionId, formValues.medias.uploads);
              } catch (error) {
                openToaster(error.toString());
                console.error(error);
              }
            }
            if (insertId) {
              navigate("../" + insertId, { replace: true, ...backHistory });
            }
          })
          .catch((error) => {
            openToaster(error.toString());
            console.error(error);
          });
      } catch (error) {
        openToaster(error.toString());
        console.error(error);
      }
    } else {
      // async: parallelize update and upload
      let promises = [updateCustomSection(formValues)];
      if (formValues.cover.uploads?.length) {
        promises.push(uploadCover(formValues.cover.uploads[0]));
      }
      if (formValues.medias.uploads?.length) {
        promises.push(uploadMedias(customSectionId, formValues.medias.uploads));
      }
      Promise.all(promises)
        .then(() => getCustomSection())
        .catch((error) => {
          openToaster(error.toString());
          console.error(error);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <AdminEditForm formData={customSectionFormData} onSubmit={submitForm} />
      )}
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}

function buildForm(customSection, onDelete) {
  let form = [];
  form.push(
    new AdminFormBuilder(AdminFormType.SELECT_MENU)
      .withKey("type")
      .withLabel("Section Type")
      .withInitialValue(customSection.type)
      .withDefaultValue(CustomSection.PRESS.key)
      .addItem(CustomSection.PRESS.key, CustomSection.PRESS.label)
      .addItem(CustomSection.HISTO.key, CustomSection.HISTO.label)
      .addItem(CustomSection.PERSO.key, CustomSection.PERSO.label)
      .makeMandatory()
      .build()
  );
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("name")
      .withLabel("Name")
      .withInitialValue(customSection.name)
      .withDefaultValue("")
      .withMaxLength(48)
      .makeMandatory()
      .build()
  );
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("link")
      .withLabel("Source link")
      .withInitialValue(customSection.link)
      .withDefaultValue("")
      .withMaxLength(255)
      .build()
  );
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_AREA)
      .withKey("description")
      .withLabel("Description")
      .withInitialValue(customSection.description)
      .withDefaultValue("")
      .build()
  );
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_AREA)
      .withKey("description_fr")
      .withLabel("Description FR")
      .withInitialValue(customSection.description_fr)
      .withDefaultValue("")
      .build()
  );

  let cover = customSection.cover_path ? [customSection.cover_path] : [];
  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("cover")
      .withLabel("Cover Picture")
      .withFolder("custom_sections")
      .withInitialValue({ pictures: cover })
      .withDefaultValue({})
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("medias")
      .withLabel("Additional pictures")
      .withFolder("custom_sections" + "/" + customSection.id)
      .withInitialValue({ pictures: customSection.medias })
      .withDefaultValue({})
      .withMultiple()
      .withOnDelete(onDelete)
      .build()
  );
  
  return form;
}
