import React from "react";
import BasketItems from "component/orders/BasketItems";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { cartItemsCountState } from "store/selectors";
import headerTexts from "translation/texts/header-texts";
import TooltipButton from "component/commons/buttons/TooltipButton";
import useTranslate from "hooks/useTranslate";
import TextElement from "component/commons/typography/TextElement";
import RightDrawerContainer from "./RightDrawerContainer";
import TextSize from "component/commons/typography/TextSize";
import TextFont from "component/commons/typography/TextFont";

export default function BasketMenu({ onClose, topMargin }) {
  const navigate = useNavigate();
  const cartItemCount = useRecoilValue(cartItemsCountState);
  const translate = useTranslate();

  return (
    <RightDrawerContainer
      paddingTop={topMargin}
      role="presentation"
      onClick={(e) => onClose(e)}
      onKeyDown={(e) => onClose(e)}
    >
      <TextElement Tag="h2" font={TextFont.CENTURY}>
        {translate(headerTexts.BASKET)}{" "}
        <TextElement Tag="span" size={TextSize.SMALL}>
          ({cartItemCount})
        </TextElement>
      </TextElement>
      <BasketItems />
      <div style={{ margin: "2rem 0", textAlign: "center" }}>
        <TooltipButton
          tooltip={
            cartItemCount === 0 ? translate(headerTexts.NO_ITEMS_IN_BASKET) : ""
          }
          onClick={() => navigate("/orders")}
        >
          {translate(headerTexts.SEE_SHOPPING_CART)}
        </TooltipButton>
      </div>
    </RightDrawerContainer>
  );
}
