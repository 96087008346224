import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ScrollToTop from "component/commons/router/ScrollToTop";
import Footer from "component/footer/Footer";
import Navigator from "component/headers/Navigator";
import { BrowserRouter as Router } from "react-router-dom";
import colors from "style/_colors.scss";
import "./App.scss";
import AppRoutes from "component/routing/AppRoutes";
import { APP_BAR_HEIGHT } from "utils/constants";
import { HelmetProvider } from "react-helmet-async";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.action,
    },
    secondary: {
      main: colors.secondaryBackground,
    },
    background: {
      primary: colors.primaryBackground,
      secondary: colors.secondaryBackground,
      light: colors.lightBackground,
    },
    text: {
      primary: colors.primaryFont,
      secondary: colors.secondaryFont,
      dark: colors.primaryBackground
    },
    action: {
      active: colors.action,
    },
  },
});

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Navigator height={APP_BAR_HEIGHT} />
          <div style={{ marginTop: APP_BAR_HEIGHT }}>
            <ScrollToTop />
            <AppRoutes />
          </div>
          <Footer />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}
