import useBackHistory from "hooks/useBackHistory";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INSERT_ID } from "utils/constants";
import AdminEditForm from "../commons/form/AdminEditForm";
import AdminFormBuilder from "../commons/form/AdminFormBuilder";
import AdminFormType from "../commons/form/AdminFormType";
import useBackendService from "hooks/useBackendService";
import Toaster, { useToaster } from "component/commons/Toaster";
import LoadingPage from "component/pages/LoadingPage";

export default function CategoryEdit() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  const [categoryFormData, setCategoryFormData] = useState(buildForm({}));

  useEffect(() => {
    if (categoryId && categoryId !== INSERT_ID) {
      getCategory(categoryId);
    }
  }, [categoryId]);

  const getCategory = (category_id) => {
    apiCall({
      method: "GET",
      url: `/category/${category_id}`,
    })
      .then((response) => {
        setCategoryFormData(buildForm(response.data));
      })
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const updateCategory = (formValues) => {
    apiCall({
      method: categoryId === INSERT_ID ? "POST" : "PUT",
      url: "/category",
      data: {
        id: categoryId,
        name: formValues.name,
      },
    })
      .then((response) => {
        const { insertId } = response.data;
        if (insertId) {
          navigate("../" + insertId, { replace: true, ...backHistory });
        }
      })
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <AdminEditForm formData={categoryFormData} onSubmit={updateCategory} />
      )}
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}

const buildForm = (category) => {
  let form = [];
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("name")
      .withLabel("Name")
      .withInitialValue(category.name)
      .withDefaultValue("")
      .withMaxLength(48)
      .makeMandatory()
      .build()
  );
  return form;
};
