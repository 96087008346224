import React from "react";
import { styled } from "@mui/material";

function TextTag({ Tag, style, className, onClick, children }) {
  return (
    <Tag style={style} className={className} onClick={onClick}>
      {children}
    </Tag>
  );
}

const TextElement = styled(TextTag)(({ size, font, transform, weigth }) => ({
  fontSize: size ? size + "rem" : undefined,
  fontFamily: font || undefined,
  fontWeight: weigth || undefined,
  textTransform: transform || undefined
}));

export default TextElement;