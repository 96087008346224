import Language from "models/Language";
import { useRecoilValue } from "recoil";
import { languageState } from "store/atoms";

const DEFAULT_KEY = Language.EN.key;

function translate(language, text) {
  return text[language.key] || text[DEFAULT_KEY];
}

export default function useTranslate() {
  const language = useRecoilValue(languageState);
  return (text) => translate(language, text);
}
