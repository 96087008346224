import { styled } from "@mui/material";
import ShowMoreButton from "component/commons/buttons/ShowMoreButton";
import ProductsGrid, { buildProductsGrid } from "component/creations/ProductsGrid";
import useBackendService from "hooks/useBackendService";
import usePageFetcher from "hooks/usePageFetcher";
import useQuery from "hooks/useQuery";
import useTranslate from "hooks/useTranslate";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import headerTexts from "translation/texts/header-texts";
import CreationFilters, { filters } from "./CreationFilters";

const OTHER_CATEGORY = "Other";
const MIN_NB_PRODUCT_PER_ROW = 2;
const MAX_NB_PRODUCT_PER_ROW = 3;

export default function ProductsOverview() {
  const query = useQuery();
  const translate = useTranslate();
  const [apiCall, isLoading] = useBackendService();

  const { data, addNextPage, resetPages, nextStart, doneFetching } = usePageFetcher();
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [orderBy, setOrderby] = useState();
  const [productsGrid, setProductsGrid] = useState([]);

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  useEffect(() => {
    const order = query.get("order");
    const doReset = order !== orderBy;
    if (doReset) {
      getProducts(doReset ? 0 : nextStart);
    } else {
      createProductGrid();
    }
  }, [data, query, filterText]);

  const getCategories = () => {
    apiCall({
      method: "GET",
      url: "/category",
    })
      .then(function (response) {
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getCollections = () => {
    apiCall({
      method: "GET",
      url: "/collection",
    })
      .then(function (response) {
        setCollections(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getProducts = (offset) => {
    const order = query.get("order");
    apiCall({
      method: "GET",
      url: "/product",
      params: {
        offset,
        order,
      },
    })
      .then(function (response) {
        if (offset === 0) {
          resetPages(response.data);
        } else {
          addNextPage(response.data);
        }
        setOrderby(order);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const createProductGrid = () => {
    const categoryFilter = query.get("category");
    const collectionFilter = query.get("collection");
    const genderFilter = query.get("gender");

    let grid = [];
    let products = filters(data, categoryFilter, collectionFilter, genderFilter, filterText);
    if (products.length) {
      grid = buildProductsGrid(
        products.slice(1, products.length),
        [[]],
        MIN_NB_PRODUCT_PER_ROW,
        MAX_NB_PRODUCT_PER_ROW
      );
      // Push front first element in its own row
      grid.unshift([products[0]]);
    }
    setProductsGrid(grid);
  };

  return (
    <div style={{ paddingTop: "3em" }}>
      <h1>{translate(headerTexts.CREATIONS)}</h1>
      <CreationFilters categories={categories} collections={collections} onSearch={setFilterText} />
      <ProductsGrid data={productsGrid} />
      <ShowMoreButton
        doneFetching={doneFetching}
        isLoading={isLoading}
        hasResults={Boolean(productsGrid.length)}
        callback={() => getProducts(nextStart)}
      />
    </div>
  );
}
