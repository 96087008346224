export default {
  COLLECTION: {
    EN: "Collection",
    FR: "Collection",
  },
  MORE_TEXT: {
    EN: "More details +",
    FR: "Voir plus +",
  },
};
