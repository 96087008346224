import config from "config";
import React from "react";
import { Helmet } from "react-helmet-async";

export default function PageHeader({ title, endpoint }) {
  return (
    <Helmet>
      <title>
        {title} | {config.title}
      </title>
      <link
        rel="alternate"
        hreflang="fr"
        href={`https://galbertparis.fr/${endpoint}`}
      />
      <link
        rel="alternate"
        hreflang="en"
        href={`https://galbertparis.com/${endpoint}`}
      />
    </Helmet>
  );
}
