import { Grid } from "@mui/material";
import Description from "component/commons/Description";
import Page from "component/commons/page/Page";
import Separator from "component/commons/Separator";
import React, { useEffect, useState } from "react";
import API from "services/API";
import craftmanshipTexts from "translation/texts/craftmanship-texts";
import menuTexts from "translation/texts/header-texts";
import { bindPicturePath } from "utils/tools";
import ContainedImage from "component/commons/images/ContainedImage";
import PaddingGrid from "component/commons/PaddingGrid";
import PageContainer from "component/commons/page/PageContainer";
import PageTitle from "component/commons/page/PageTitle";
import useTranslate from "hooks/useTranslate";
import useTranslateDescription from "hooks/useTranslateDescription";

export default function CraftmanshipPage() {
  const translate = useTranslate();

  const [sections, setSections] = useState([]);

  useEffect(() => {
    getSections();
  }, []);

  const getSections = () => {
    API.get("/custom-section", {
      params: {
        sectionType: "PERSO",
      },
    })
      .then(function (response) {
        setSections(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Page
      title={translate(menuTexts.CRAFTMANSHIP)}
      endpoint="craftmanship"
      variant="lightBackground"
    >
      <PageContainer maxWidth="lg">
        <PageTitle>{translate(menuTexts.CRAFTMANSHIP)}</PageTitle>
        <div>
          {sections.map((section, index) => (
            <CraftmanshipDetails
              key={section.id}
              reverse={index % 2}
              data={section}
            />
          ))}
        </div>
      </PageContainer>
    </Page>
  );
}

function CraftmanshipDetails({ data, reverse }) {
  const translate = useTranslate();
  const translateDescription = useTranslateDescription();

  return (
    <div>
      <Grid
        container
        direction={reverse ? "row-reverse" : "row"}
        justifyContent="center"
        alignItems="center"
      >
        <PaddingGrid item xs={12} sm={6}>
          <h2>{data.name}</h2>
          <Separator />
          <div>
            <Description
              text={translateDescription(data)}
              maxNbLines={20}
              showMoreText={translate(craftmanshipTexts.READ_MORE)}
            />
          </div>
        </PaddingGrid>
        <PaddingGrid item xs={12} sm={6}>
          <ContainedImage
            src={bindPicturePath("custom_sections", data.cover_path)}
          />
        </PaddingGrid>
      </Grid>
    </div>
  );
}
