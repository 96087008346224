export default {
  PAIEMENT_COMPLETED: {
    key: "PAIEMENT_COMPLETED",
    label: "Paiement completed",
    label_fr: "Paiement effectué",
  },
  DEVLIVERED: {
    key: "DEVLIVERED",
    label: "Delivered",
    label_fr: "Livré",
  },
  RECEIVED: {
    key: "RECEIVED",
    label: "Received",
    label_fr: "Reçue",
  },
};
