import { Container } from "@mui/material";
import Description from "component/commons/Description";
import Page from "component/commons/page/Page";
import Separator from "component/commons/Separator";
import ProductsGrid, { buildProductsGrid } from "component/creations/ProductsGrid";
import useBackendService from "hooks/useBackendService";
import useTranslate from "hooks/useTranslate";
import useTranslateDescription from "hooks/useTranslateDescription";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import collectionTexts from "translation/texts/collection-texts";
import headerTexts from "translation/texts/header-texts";
import { bindPicturePath } from "utils/tools";

const MIN_NB_PRODUCT_PER_ROW = 1;
const MAX_NB_PRODUCT_PER_ROW = 2;

export default function CollectionPage() {
  const translate = useTranslate();

  return (
    <Page
      title={translate(headerTexts.COLLECTIONS)}
      endpoint="collection"
      variant="lightBackground"
    >
      <Outlet />
    </Page>
  );
}

export function SingleCollection() {
  let { collectId } = useParams();
  const translate = useTranslate();
  const translateDescription = useTranslateDescription();
  const [apiCall, isLoading] = useBackendService();

  const [collect, setCollect] = useState(null);
  const [productsGrid, setProductsGrid] = useState([]);

  useEffect(() => {
    if (collectId) {
      getCollect();
    }
  }, [collectId]);

  const getCollect = () => {
    apiCall({
      method: "GET",
      url: `/collection/${collectId}`,
    })
      .then(function (response) {
        setCollect(response.data);
        getProducts(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getProducts = (collection) => {
    apiCall({
      method: "GET",
      url: "/product",
      params: {
        collect_id: collectId,
      },
    })
      .then(function (response) {
        let grid = [];
        let products = response.data;
        if (products.length) {
          grid = buildProductsGrid(products, [[]], MIN_NB_PRODUCT_PER_ROW, MAX_NB_PRODUCT_PER_ROW);
          if (collection.cover_path) {
            grid.splice(grid.length / 2, 0, {
              cover: bindPicturePath(`collects/${collectId}`, collection.cover_path),
            });
          }
        }
        setProductsGrid(grid);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div style={{ paddingTop: "3em" }}>
      <h3>{translate(collectionTexts.COLLECTION)}</h3>
      <h1>{Boolean(collect) && collect.name}</h1>

      <Separator color="gold" />

      <Container maxWidth="md">
        <Description
          text={collect ? translateDescription(collect) : ""}
          maxNbLines={10}
          showMoreText={translate(collectionTexts.MORE_TEXT)}
        />
      </Container>

      <ProductsGrid data={productsGrid} />
    </div>
  );
}
