import { styled } from "@mui/material";
import React from "react";
import "style/commons.scss"

const PipeList = styled("ul")(() => `
  font-size: 1.1em;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;

  & li:last-child {
    display: none;
  }

  & li {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`)

export default function PipeBreadcrumbs({
  elements,
  isActive,
  callback,
}) {

  const getClassName = (element) => {
    return isActive(element.id) ? "active" : "clickable"
  };

  return (
    <PipeList>
      {elements.map((element, index) => (
        <React.Fragment key={element.id}>
          <li className={getClassName(element)} onClick={() => callback(element.id)}>{element.label}</li>
          <li>|</li>
        </React.Fragment>
      ))}
    </PipeList>
  );
}
