import { Container, Grid, styled } from "@mui/material";
import PipeBreadcrumbs from "component/commons/PipeBreadcrumbs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "services/API";
import ProductCarousel from "./ProductCarousel";
import ProductDetails from "./ProductDetails";
import ProductMiniature from "./ProductMiniature";
import creationsTexts from "translation/texts/creations-texts";
import useTranslate from "hooks/useTranslate";
import { GRADIENT_BACKGROUND } from "utils/constants";
import useGoBack from "hooks/useGoBack";
import TextElement from "component/commons/typography/TextElement";
import TextFont from "component/commons/typography/TextFont";
import { bindPicturePath } from "utils/tools";

const ROOT = "/creations"

const ProductGridContainer = styled(Grid)(() => ({
  background: GRADIENT_BACKGROUND,
    padding: "2rem",
}))

const ProductGridItem = styled(Grid)(() => ({
  padding: "0 0.5rem"
}))

export default function ProductOverview() {
  const navigate = useNavigate();
  const goBack = useGoBack();
  const location = useLocation();
  const translate = useTranslate();

  const { productId } = useParams();

  const [product, setProduct] = useState(null);
  const [pics, setPics] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const getProductDetails = () => {
      API.get(`/product/${productId}`)
        .then(function (response) {
          const productData = response.data;
          setProduct(productData);
          setPics(productData.pics);
          setColors(productData.colors);
          if (Boolean(productData.size)) {
            setSizes(productData.size.split("\n"));
          } else {
            setSizes([]);
          }
          getProductSuggestions(productData);
        })
        .catch(function (error) {
          console.error(error);
        });
    };
    getProductDetails();
  }, [productId]);

  const getProductSuggestions = (product) => {
    API({
      method: "GET",
      url: "/product/suggestion",
      params: {
        id: product.id,
        name: product.name,
        collect_id: product.collect_id,
      },
    })
      .then(function (response) {
        setSuggestions(response.data);
      })
      .catch(function (error) {
        setSuggestions([]);
        console.error(error);
      });
  };

  const getBreadCrumbs = () => {
    let breadCrumbs = [{ label: "Creations", id: ROOT }];
    if (product) {
      const categoryName = product.category_name
        ? product.category_name
        : "Other";
      breadCrumbs.push({
        label: categoryName,
        id: `${ROOT}?category=${encodeURIComponent(categoryName)}`,
      });
      breadCrumbs.push({ id: `${ROOT}/${product.id}` , label: product.name });
    }
    return breadCrumbs;
  };

  const activateProduct = (path) => {
    return product && path === `${ROOT}/${product.id}`;
  };

  const onBreadcumbsClick = (path) => {
    const previousPath = location.state?.previousRoute.pathname + location.state?.previousRoute.search;
    if (previousPath === path) {
        goBack();
    } else {
      navigate(path)
    }
  }

  return (
    <div>
      <div style={{padding: "1.5rem"}}>
        <PipeBreadcrumbs
          elements={getBreadCrumbs()}
          isActive={activateProduct}
          callback={onBreadcumbsClick}
        />
      </div>

      <ProductGridContainer container alignItems="center">
        <ProductGridItem item xs={12} md={6}>
          <ProductCarousel productId={productId} pics={pics} />
        </ProductGridItem>
        {product && (
          <ProductGridItem item xs={12} md={6}>
            <ProductDetails product={product} sizes={sizes} colors={colors} />
          </ProductGridItem>
        )}
      </ProductGridContainer>
      
      {suggestions.length && (
        <Container maxWidth="md" sx={{padding: "3rem 1rem"}}>
          <TextElement Tag="h3" font={TextFont.ARIAL}>{translate(creationsTexts.YOU_MAY_LIKE)}&hellip;</TextElement>
          <Grid
            container
            spacing={10}
            direction="row"
            justifycontent="center"
            alignItems="center"
          >
            {suggestions.map((suggestion) => (
              <Grid item key={suggestion.id} xs={12} sm={3}>
                <ProductMiniature to={`../${suggestion.id}`} name={suggestion.name} src={bindPicturePath("products", suggestion.id, suggestion.first_pic)} />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </div>
  );
}
