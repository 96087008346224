const { styled } = require("@mui/material");

const BackgroundImage = styled("div")(
  () => `
  width: 100%;
  max-height: 80vh;
  padding-bottom: 100%;
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
);

export default BackgroundImage;
