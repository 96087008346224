import React from "react";
import Icon from "@mui/material/Icon";
import { styled } from "@mui/material";

const StyledAnchor = styled("a")(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  & > span {
    width: auto;
  }
  &:hover {
    cursor: pointer;
    color: ${theme.palette.action.active} !important;
  }
`
);

export default function MediaIconLink({ href, icon }) {
  return (
    <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
      <Icon className={icon} />
    </StyledAnchor>
  );
}
