import { styled } from "@mui/material";
import React from "react";
import TextSize from "../typography/TextSize";
import useTranslate from "hooks/useTranslate";
import TextElement from "../typography/TextElement";
import useGoBack from "hooks/useGoBack";
import { useLocation } from "react-router-dom";

const StyledButton = styled("button")(({ theme }) => ({
  color: theme.palette.text.primary,
  "&:hover": {
    color: theme.palette.action.active,
    cursor: "pointer",
  },
  background: "none",
  border: "none",
}));

const ButtonContainer = styled("div")(({goBackEnabled}) => ({
  padding: "1rem",
  display: goBackEnabled || "none"
}))

const back = {
  EN: "Back",
  FR: "Retour",
};

export default function BackButton() {
  const location = useLocation();
  const goBack = useGoBack();
  const translate = useTranslate();

  return (
    <ButtonContainer goBackEnabled={location.state?.goBackEnabled}>
      <StyledButton onClick={goBack}>
        <TextElement Tag="span" size={TextSize.EXTRA_LARGE}>&lt; {translate(back)}</TextElement>
      </StyledButton>
    </ButtonContainer>
  );
}
