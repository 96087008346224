import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fab } from "@mui/material";
import AddButton from "component/commons/buttons/AddButton";
import ContainedImage from "component/commons/images/ContainedImage";
import Toaster, { useToaster } from "component/commons/Toaster";
import PromotedItemsUtils from "component/home/PromotedItemsUtils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "services/API";
import { INSERT_ID } from "utils/constants";
import { bindPicturePath } from "utils/tools";
import AdminTable from "../commons/AdminTable";
import useBackendService from "hooks/useBackendService";
import AdminFormBuilder from "../commons/form/AdminFormBuilder";
import AdminFormType from "../commons/form/AdminFormType";
import AdminEditForm from "../commons/form/AdminEditForm";
import useBackHistory from "hooks/useBackHistory";
import picturesUpload from "../commons/pictures-upload";

const TABLE_HEADERS = ["ID", "Type", "Name", "Position"];
const PICTURE_INDEX = 2;

export default function PromotedItemsTable() {
  let navigate = useNavigate();
  const backHistory = useBackHistory();
  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  const [promotedItemsData, setPromotedItemsData] = useState([]);
  const [carouselFormData, setCarouselFormData] = useState(buildCarouselForm([]));

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    apiCall({
      method: "GET",
      url: "/home",
    })
      .then(function (response) {
        if (response.data) {
          setCarouselFormData(buildCarouselForm(response.data.carousel, deletePicture));
          let tableData = [];
          response.data.items.forEach((item) =>
            tableData.push({
              id: item.id,
              cells: [
                item.id,
                PromotedItemsUtils.getTypeLabel(item),
                PromotedItemsUtils.getName(item),
                item.position,
              ],
            })
          );
          setPromotedItemsData(tableData);
        } else {
          openToaster("Error while fetching promoted items");
          console.error("Error while fetching promoted items");
        }
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const editItem = (itemId) => {
    navigate(encodeURI(itemId), { ...backHistory });
  };

  const uploadCarouselImages = (formValues) => {
    picturesUpload(apiCall, formValues.carousel.uploads, "promotedItem_carousel", "home/carousel")
      .then(function () {
        getItems();
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const removeItem = (itemId) => {
    const picture = promotedItemsData.find((item) => item.id === itemId)?.cells[PICTURE_INDEX];
    apiCall({
      method: "DELETE",
      url: `/home/${itemId}`,
      data: {
        picture,
      },
    })
      .then(function () {
        getItems();
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const deletePicture = (picture) => {
    apiCall({
      method: "DELETE",
      url: `/upload/home/carousel`,
      data: {
        picture,
      },
    })
      .then(function () {
        getItems();
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  return (
    <>
      <AdminEditForm formData={carouselFormData} onSubmit={uploadCarouselImages} />
      <AdminTable
        name="promoted-items"
        headers={TABLE_HEADERS}
        rows={promotedItemsData}
        editAction={(itemId) => editItem(itemId)}
        deleteAction={(itemId) => removeItem(itemId)}
      />
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
      <AddButton onClick={() => editItem(INSERT_ID)} />
    </>
  );
}

function buildCarouselForm(carouselImages, onDelete) {
  let form = [];
  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("carousel")
      .withLabel("Carousel images")
      .withFolder("promotion/carousel")
      .withInitialValue({ pictures: carouselImages })
      .withDefaultValue({})
      .withMultiple()
      .withOnDelete(onDelete)
      .build()
  );
  return form;
}
