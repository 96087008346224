import React, { useState } from "react";
import TooltipButton from "component/commons/buttons/TooltipButton";
import { useRecoilState } from "recoil";
import { cartState } from "store/atoms";
import creationsTexts from "translation/texts/creations-texts";
import { IconButton, Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";
import useTranslate from "hooks/useTranslate";

export default function AddToShoppingCart({
  product,
  size,
  color,
  shouldHaveSize,
  shouldHaveColor,
}) {
  const translate = useTranslate();

  const [cart, setCart] = useRecoilState(cartState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = () => {
    let tmpCart = cart.slice();
    tmpCart.push({
      product,
      size,
      color,
    });
    setCart(tmpCart);
    setSnackbarOpen(true);
  };

  const getDisabledTooltip = () => {
    let text = "";
    if (shouldHaveSize && !size) {
      text += translate(creationsTexts.SIZE);
    }

    if (shouldHaveColor && !color) {
      if (text.length) {
        text += ` ${translate(creationsTexts.AND)} `;
      }
      text += translate(creationsTexts.COLOR);
    }

    return text.length ? translate(creationsTexts.YOU_NEED_TO) + text : "";
  };

  return (
    <>
      <TooltipButton tooltip={getDisabledTooltip()} onClick={handleSubmit}>
        {translate(creationsTexts.ADD_TO_SHOPPING_CART)}
      </TooltipButton>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={5000}
        message={translate(creationsTexts.ADDED_TO_SHOPPING_CART)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
