import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";

export default function OrderTable() {
  const [orders, setOrders] = useState([]);
  const sessionToken = useRecoilValue(sessionTokenState);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    API({
      method: "GET",
      url: "/order/admin",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function (response) {
        setOrders(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Creation date</TableCell>
              <TableCell>Last update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.id}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>
                  {order.firstname} {order.lastname}
                </TableCell>
                <TableCell>{order.email}</TableCell>
                <TableCell>{order.currency}</TableCell>
                <TableCell>{order.creation_ts}</TableCell>
                <TableCell>{order.last_update}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
