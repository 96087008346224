const Language = {
  EN: { key: "EN" },
  FR: { key: "FR" },
};

export function isFrench(language) {
  return language.key === Language.FR.key;
}

export function getDefaultLanguage() {
  if (window.location.hostname.endsWith(".fr")) {
    return Language.FR;
  } else {
    return Language.EN;
  }
}

export default Language;
