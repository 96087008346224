import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "style/override-slicker.scss";
import { useRef } from "react";
import { bindPicturePath } from "utils/tools";
import CoverImage from "component/commons/images/CoverImage";
import FlatDot from "./FlatDot";

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  pauseOnDotsHover: false,
  pauseOnFocus: false,
};

export default function MainCarousel({ sources }) {
  const slicker = useRef(null);

  const appendDots = (dots) => {
    let newList = [];

    dots.forEach((listElm, i) => {
      let isActive = false;

      if (listElm.props.className === "slick-active") {
        isActive = true;
      }

      newList.push(
        <li key={i} className="slick-list-element">
          <FlatDot
            active={isActive}
            onClick={() => slicker.current.slickGoTo(i)}
          />
        </li>
      );
    });

    return <ul>{newList}</ul>;
  };

  return (
    <Slider {...settings} appendDots={appendDots} ref={slicker}>
      {sources.map((source) => (
        <CoverImage
          key={source}
          src={bindPicturePath("promotion/carousel", source)}
        />
      ))}
    </Slider>
  );
}
