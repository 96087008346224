import React from "react";
import { Container, Grid } from "@mui/material";
import IconTextLink from "component/commons/buttons/IconTextLink";

export default function AdministrationMenu({ elements }) {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          width: "100%",
          paddingTop: "2em",
          paddingBottom: "2em",
        }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {elements.map((element) => (
          <Grid
            key={element.path}
            item
            xs={12}
            sm={4}
            style={{ textAlign: "center", padding: 10 }}
          >
            <IconTextLink
              to={element.path}
              icon={element.icon}
              text={element.text}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
