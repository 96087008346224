import Badge from "@mui/material/Badge";
import { styled } from "@mui/material";

const ActionBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 2,
    right: 2,
    padding: 0,
    fontSize: "0.6rem",
  },
}));

export default ActionBadge;
