export default {
  EUR: { key: "EUR", char: "€" },
  USD: { key: "USD", char: "$" },
  GBP: { key: "GBP", char: "£" },
};

export function formatCurrency(currency, amount) {
  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  });
  return formatter.format(amount);
}
