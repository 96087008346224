import React from "react";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { Collapse, ListItemButton, styled } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Anchor from "component/commons/Anchor";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "services/API";
import Roles from "models/Roles";
import useAuthorization from "hooks/useAuthorization";
import menuTexts from "translation/texts/header-texts";
import CustomSection from "models/CustomSection";
import SocialLinks from "component/footer/SocialLinks";
import useTranslate from "hooks/useTranslate";
import useTranslateLabel from "hooks/useTranslateLabel";
import LeftDrawerContainer from "./LeftDrawerContainer";

const ListItemHeader = styled(ListItemText)(
  ({ theme }) => `
  color: ${theme.palette.text.secondary};
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: smaller;`
);

const ListItemActionButton = styled(ListItemButton)(
  ({ theme }) => `
  padding: 0.5em 2em;
  text-transform: uppercase;
  font-size: large;
  &:hover {
    color: ${theme.palette.action.active};
  }`
);

const ListItemSubButton = styled(ListItemButton)(
  ({ theme }) => `
  padding: 0 2em;
  &:hover {
    color: ${theme.palette.action.active};
  }`
);

export default function MainMenu({ onClose, topMargin }) {
  const translate = useTranslate();
  const translateLabel = useTranslateLabel();
  const { isAuthorized } = useAuthorization();

  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
    getCollects();
  }, []);

  const getCategories = () => {
    API.get("/category")
      .then(function (response) {
        setCategories(
          response.data.map((category) => {
            return {
              title: category.name,
              path: {
                pathname: "/creations",
                search: new URLSearchParams({
                  category: category.name,
                }).toString(),
              },
            };
          })
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getCollects = () => {
    API.get("/collection")
      .then(function (response) {
        setCollections(
          response.data.map((collect) => {
            return {
              title: collect.name,
              path: `/collection/${collect.id}`,
            };
          })
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <LeftDrawerContainer
    paddingTop={topMargin}
      role="presentation"
      onClick={(e) => onClose(e)}
      onKeyDown={(e) => onClose(e)}
    >
      <List>
        <MenuItemCollapsible
          title={translate(menuTexts.COLLECTIONS)}
          items={collections}
        />
        <MenuItemCollapsible
          title={translate(menuTexts.CREATIONS)}
          path="/creations"
          items={categories}
        />
        <MenuItem
          title={translate(menuTexts.CRAFTMANSHIP)}
          path="/craftmanship"
        />
        <MenuItem title={translate(menuTexts.HISTORY)} path="/history" />
        <MenuItem title={translateLabel(CustomSection.PRESS)} path="/press" />
        <MenuItem title={translate(menuTexts.CONTACT)} path="/contact" />
      </List>

      {isAuthorized([Roles.ADMIN, Roles.OWNER]) && (
        <>
          <Divider />
          <List>
            <ListItemHeader
              disableTypography={true}
              primary={translate(menuTexts.ADMIN_SECTION)}
            />
            <MenuItem
              title={translate(menuTexts.ADMINISTRATION)}
              icon={faCogs}
              path="/administration"
            />
            <MenuExternalLink
              title={translate(menuTexts.ANALYTICS)}
              path="https://analytics.google.com/analytics/web/#/report-home/a160305496w231200894p217376844"
            />
            <MenuExternalLink
              title={translate(menuTexts.SEARCH_CONSOLE)}
              path="https://search.google.com/search-console?resource_id=sc-domain%3Agalbertparis.com&hl=fr"
            />
          </List>
        </>
      )}

      <SocialLinks containerStyle={{ paddingLeft: "1rem" }} innerPadding={1} />
    </LeftDrawerContainer>
  );
}

function MenuItem({ title, path }) {
  let navigate = useNavigate();

  return (
    <ListItemActionButton onClick={() => navigate(path)}>
      <ListItemText disableTypography={true} primary={title} />
    </ListItemActionButton>
  );
}

function MenuExternalLink({ title, path }) {
  return (
    <ListItemActionButton>
      <Anchor>{title}</Anchor>
    </ListItemActionButton>
  );
}

function MenuItemCollapsible({ title, path, items }) {
  let navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const hanldeCollapse = (e, open) => {
    e.stopPropagation();
    setOpen(open);
  };

  const handleClick = (e) => {
    if (path) {
      navigate(path);
    } else {
      e.stopPropagation();
      setOpen(!isOpen);
    }
  };

  return (
    <>
      <ListItemActionButton onClick={handleClick}>
        <ListItemText disableTypography={true} primary={title} />
        {isOpen ? (
          <ExpandLess onClick={(e) => hanldeCollapse(e, false)} />
        ) : (
          <ExpandMore onClick={(e) => hanldeCollapse(e, true)} />
        )}
      </ListItemActionButton>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {items.map((item) => (
            <ListItemSubButton
              key={item.title}
              onClick={() => navigate(item.path)}
            >
              <ListItemText primary={item.title} />
            </ListItemSubButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}
