export default {
  BASKET: {
    EN: "Basket",
    FR: "Panier",
  },
  COLOR: {
    EN: "Color",
    FR: "Couleur",
  },
  DELIVERY: {
    EN: "Delivery",
    FR: "Livraison",
  },
  FREE: {
    EN: "Free",
    FR: "Gratuit",
  },
  LAST_STATUS: {
    EN: "Last status",
    FR: "Dernier status",
  },
  MY_ORDERS: {
    EN: "My orders",
    FR: "Mes commandes",
  },
  NAME: {
    EN: "Name",
    FR: "Nom",
  },
  ORDER_NO: {
    EN: "Order N°",
    FR: "Numéro de commande",
  },
  ORDER_PLACED_ON: {
    EN: "Order placed on",
    FR: "Commande effectuée le",
  },
  ORDER_SUCESS: {
    EN: "The order has been placed successfully!",
    FR: "La commande a bien été effectuée !",
  },
  ORDER_CANCELLED: {
    EN: "The order has been cancelled",
    FR: "La commande a été annulée",
  },
  PLACE_ORDER: {
    EN: "Place order",
    FR: "Commander",
  },
  VAT: {
    EN: "VAT",
    FR: "TVA",
  },
  SEE_ORDER_ITEMS: {
    EN: "See order items",
    FR: "Voir les produits",
  },
  SIZE: {
    EN: "Size",
    FR: "Taille",
  },
  SUBTOTAL: {
    EN: "Subtotal",
    FR: "Sous-total",
  },
  TOTAL: {
    EN: "Total",
    FR: "Total",
  },
  YOU_MUST_BE_SIGNED_IN: {
    EN: "You must be signed it to place an order",
    FR: "Vous devez être connecté pour passer commande",
  },
};
