import { useRecoilValue } from "recoil";
import { userRoleState } from "store/selectors";

export default function useAuthorization() {
  const userRole = useRecoilValue(userRoleState);

  const isAuthorized = (roles) => {
    return roles.indexOf(userRole) !== -1;
  };

  return { isAuthorized };
}
