const TextSize = {
  EXTRA_SMALL: 0.6,
  SMALL: 0.8,
  MEDIUM: 1,
  LARGE: 1.1,
  VERY_LARGE: 1.2,
  EXTRA_LARGE: 1.25,
  ULTRA_LARGE: 1.5,
};

export default TextSize;
