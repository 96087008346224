export default {
  ADD_TO_SHOPPING_CART: {
    EN: "Add to shopping cart",
    FR: "Ajouter au panier",
  },
  ADDED_TO_SHOPPING_CART: {
    EN: "Product has been added to the shopping cart!",
    FR: "Le produit a bien été ajouté au panier !",
  },
  AND: {
    EN: "and",
    FR: "et",
  },
  COLOR: {
    EN: "a color",
    FR: "une couleur",
  },
  COLORS: {
    EN: "Colors",
    FR: "Couleurs",
  },
  DELIVERY_AND_RETURNS: {
    EN: "Delivery and returns",
    FR: "Livraison et retours",
  },
  FILTERS: {
    EN: "Filters",
    FR: "Filtres",
  },
  GENDER: {
    EN: "Gender",
    FR: "Genre",
  },
  MORE_DETAILS: {
    EN: "More details +",
    FR: "Plus de détails +",
  },
  NO_RESULT: {
    EN: "There is no result for this filters and search criteria. You may want to broaden your search.",
    FR: "Aucun résultat trouvé pour vos critères de recherche. Essayez d'effectuer une recherche plus global.",
  },
  ORDER: {
    EN: "Order",
    FR: "Ordre",
  },
  SEARCH: {
    EN: "Search",
    FR: "Recherche",
  },
  SIZE: {
    EN: "a size",
    FR: "une taille",
  },
  SIZES: {
    EN: "Sizes",
    FR: "Tailles",
  },
  SHOW_MORE: {
    EN: "Show more",
    FR: "Voir plus",
  },
  STARTS_AT: {
    EN: "Starts at",
    FR: "À partir de",
  },
  YOU_MAY_LIKE: {
    EN: "You may also like",
    FR: "Vous aimerez aussi",
  },
  YOU_NEED_TO: {
    EN: "You need to pick ",
    FR: "Vous devez selectionner ",
  },
};
