export default {
  PRESS: {
    key: "PRESS",
    label: "Press",
    label_fr: "Presse",
    endpoint: "press",
  },
  HISTO: {
    key: "HISTO",
    label: "History",
    label_fr: "Histoire",
    endpoint: "history",
  },
  PERSO: {
    key: "PERSO",
    label: "Craftmanship",
    label_fr: "Savoir faire",
    endpoint: "craftmanship",
  },
  PRIVACY: {
    key: "PRIVACY",
    label: "Privacy Policy",
    label_fr: "Politique de Confidentialité",
    endpoint: "privacy-policy",
  },
  SHIPPING: {
    key: "SHIPPING",
    label: "Shipping Policy",
    label_fr: "Politique de Livraison",
    endpoint: "shipping-policy",
  },
  TERMS: {
    key: "TERMS",
    label: "Terms of Service",
    label_fr: "Conditions d'Utilisation",
    endpoint: "terms-of-service",
  },
  CERTIFS: {
    key: "CERTIFS",
    label: "Label and Certifications",
    label_fr: "Label et Certifications",
    endpoint: "label-and-certifications",
  },
  ABOUT: {
    key: "ABOUT",
    label: "About us",
    label_fr: "À propos",
    endpoint: "about-us",
  },
  SUSTAIN: {
    key: "SUSTAIN",
    label: "Sustainability",
    label_fr: "Développement Durable",
    endpoint: "sustainability",
  },
};
