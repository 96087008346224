import CustomSection from "models/CustomSection";
import { bindServerPath } from "utils/tools";

export const PROMTED_ITEM_TYPES = {
  YOUTUBE: { id: "YOUTUBE", label: "Youtube video" },
  COLLECT: { id: "COLLECT", label: "Collection" },
  CUSTOM_SECTION: { id: "CUSTOM_SECTION", label: "Custom Section" },
  PICTURE: { id: "PICTURE", label: "Picture" },
};

export default class PromotedItemsUtils {
  static getType(item) {
    if (item.youtube_id) {
      return PROMTED_ITEM_TYPES.YOUTUBE;
    } else if (item.collect_id) {
      return PROMTED_ITEM_TYPES.COLLECT;
    } else if (item.custom_section_id) {
      return PROMTED_ITEM_TYPES.CUSTOM_SECTION;
    } else if (item.picture) {
      return PROMTED_ITEM_TYPES.PICTURE;
    }
    return { id: "" };
  }

  static getTypeLabel(item) {
    let type = this.getType(item);
    if (type === PROMTED_ITEM_TYPES.CUSTOM_SECTION) {
      return CustomSection[item.custom_section_type].label;
    } else {
      return type.label;
    }
  }

  static getName(item) {
    if (item.youtube_id) {
      return this.youtubeVideoWatchLink(item.youtube_id);
    } else if (item.collect_id) {
      return item.collect_name;
    } else if (item.custom_section_id) {
      return item.custom_section_name;
    } else if (item.picture) {
      return item.picture;
    }
    return "";
  }

  static getPictureFullLink(name) {
    return bindServerPath(`uploads/promotion/${name}`);
  }

  static youtubeVideoWatchLink(video_id) {
    return `https://www.youtube.com/watch?v=${video_id}`;
  }

  static youtubeVideoEmbedLink(video_id, args) {
    if (args) {
      return `https://www.youtube.com/embed/${video_id}?${args}`;
    }
    return `https://www.youtube.com/embed/${video_id}`;
  }
}
