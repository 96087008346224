import { styled } from "@mui/material";

function getColor(theme, color) {
  if (color === "gold") {
    return theme.palette.action.active;
  } else if (color === "grey") {
    return theme.palette.text.secondary;
  } else {
    return theme.palette.text.primary;
  }
}

const Separator = styled("hr")(({ theme, color, fullWidth, margin, thin }) => ({
  border: (thin ? "0.5" : "1") + "px solid " + getColor(theme, color),
  backgroundColor: getColor(theme, color),
  width: fullWidth ? "100%" : "5rem",
  marginTop: margin || 0,
  marginBottom: margin || 0,
}));

export default Separator;
