import { isFrench } from "models/Language";
import { useRecoilValue } from "recoil";
import { languageState } from "store/atoms";

function translateDescription(language, element) {
  if (isFrench(language) && Boolean(element.description_fr)) {
    return element.description_fr;
  } else {
    return element.description;
  }
}

export default function useTranslateDescription() {
  const language = useRecoilValue(languageState);
  return (element) => translateDescription(language, element);
}
