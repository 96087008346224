import { Grid } from "@mui/material";
import React from "react";
import { bindPicturePath } from "utils/tools";
import ColorPicture from "component/creations/ColorPicture";
import ordersTexts from "translation/texts/orders-texts";
import useTranslate from "hooks/useTranslate";

export default function OrderItem({ item }) {
  const translate = useTranslate();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <img
        alt={item.name}
        src={bindPicturePath("products", item.product_id, item.first_pic)}
      />
      <table>
        <thead>
          <tr>
            <td>{translate(ordersTexts.NAME)}:</td>
            {Boolean(item.size) && <td>{translate(ordersTexts.SIZE)}:</td>}
            {Boolean(item.size) && <td>{translate(ordersTexts.COLOR)}:</td>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{item.name}</td>
            {Boolean(item.size) && <td>{item.size}</td>}
            {Boolean(item.size) && (
              <td>
                <ColorPicture
                  colorPicture={item.color}
                  productId={item.product_id}
                />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </Grid>
  );
}
