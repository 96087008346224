import { Collapse, styled } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Description from "component/commons/Description";
import Separator from "component/commons/Separator";
import Tag from "component/commons/Tag";
import Availability from "models/Availability";
import React, { useEffect, useState } from "react";
import creationsTexts from "translation/texts/creations-texts";
import { displayPrice } from "utils/tools";
import AddToShoppingCart from "./AddToShoppingCart";
import ColorPicture from "./ColorPicture";
import ShippingInfo from "./ShippingInfo";
import useTranslateDescription from "hooks/useTranslateDescription";
import useTranslate from "hooks/useTranslate";
import useTranslateLabel from "hooks/useTranslateLabel";
import TextElement from "component/commons/typography/TextElement";

const SEPARATOR_MARGIN = 20;

const CenteredDiv = styled("div")(() => ({
  textAlign: "center"
}))

const Subtitle = styled(TextElement)(() => ({
  textTransform: "uppercase"
}))

const SubList = styled("ul")(() => ({
  listStyle: "none",
  margin: 0,
  padding: 0,
  "& li": {
    margin: 5,
    display: "inline-block",
  },
}));

export default function ProductDetails({ product, sizes, colors }) {
  const translate = useTranslate();
  const translateLabel = useTranslateLabel();
  const translateDescription = useTranslateDescription();

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [displayShipInfo, setDisplayShipInfo] = useState(false);

  useEffect(() => {
    setSelectedSize(null);
    setSelectedColor(null);
  }, [product]);

  useEffect(() => {
    if (sizes.length === 1) {
      setSelectedSize(sizes[0]);
    }
  }, [sizes]);

  useEffect(() => {
    if (colors.length === 1) {
      setSelectedColor(0);
    }
  }, [colors]);

  const isBuyable = () => {
    return (
      Boolean(product.price) && product.availability !== Availability.UNIQUE.key
    );
  };

  return (
    <>
      <div>
        <TextElement Tag="h2">{product.name}</TextElement>
        {product.price && (
          <CenteredDiv>
            {translate(creationsTexts.STARTS_AT)}{" "}
            <b>{displayPrice(product.price)}</b>
          </CenteredDiv>
        )}
      </div>

      <Separator color="gold" fullWidth={true} margin={SEPARATOR_MARGIN} />

      <div>
        <Description
          text={translateDescription(product)}
          maxNbLines={5}
          showMoreText={translate(creationsTexts.MORE_DETAILS)}
        />
      </div>

      <Separator color="gold" fullWidth={true} margin={SEPARATOR_MARGIN} />

      {sizes.length !== 0 && (
        <CenteredDiv>
          <Subtitle Tag="h4">{translate(creationsTexts.SIZES)}</Subtitle>
          <SubList>
            {sizes.map((size) => (
              <li key={size}>
                <Tag
                  onClick={() => setSelectedSize(size)}
                  selected={selectedSize === size}
                >{size}</Tag>
              </li>
            ))}
          </SubList>
        </CenteredDiv>
      )}

      {colors.length > 0 && (
        <CenteredDiv>
          <Subtitle Tag="h4">{translate(creationsTexts.COLORS)}</Subtitle>
          <SubList>
            {colors.map((color_path, index) => (
              <li key={color_path} style={{ width: "2rem" }}>
                <ColorPicture
                  colorPicture={color_path}
                  productId={product.id}
                  selected={index === selectedColor}
                  onClick={() => {
                    setSelectedColor(index);
                  }}
                />
              </li>
            ))}
          </SubList>
        </CenteredDiv>
      )}
      {Boolean(product.availability) && (
        <CenteredDiv>
          <TextElement Tag="h4">
            {translateLabel(Availability[product.availability])}
          </TextElement>
        </CenteredDiv>
      )}

      <Separator color="gold" fullWidth={true} margin={SEPARATOR_MARGIN} />

      {isBuyable() && (
        <CenteredDiv>
          <AddToShoppingCart
            product={product}
            size={selectedSize}
            color={colors[selectedColor]}
            shouldHaveSize={Boolean(sizes.length)}
            shouldHaveColor={Boolean(colors.length)}
          />
        </CenteredDiv>
      )}

      <div>
        <TextElement
          Tag="h4"
          className="clickable"
          style={{ textAlign: "center" }}
          onClick={() => setDisplayShipInfo(!displayShipInfo)}
        >
          <span style={{ verticalAlign: "super" }}>
            {translate(creationsTexts.DELIVERY_AND_RETURNS)}{" "}
          </span>
          {displayShipInfo ? <ExpandLess /> : <ExpandMore />}
        </TextElement>
        <Collapse in={displayShipInfo}>
          <ShippingInfo />
        </Collapse>
      </div>
    </>
  );
}
