import config from "config";
import borderBackground from "media/border-background.png";
import Currency, { formatCurrency } from "models/Currency";
import moment from "moment/moment";
import "moment/locale/fr";
import { isFrench } from "models/Language";

const PICTURE_LINK_REGEX = /(http|https):\/\/.+\.(png|jpg|jpeg|gif)$/gi

export function bindPicturePath(...paths) {
  return config.serverURL + "/uploads/" + paths.join("/");
}

export function bindServerPath(path) {
  return config.serverURL + "/" + path;
}

export function explodePath2getName(path) {
  let names = path.split("/");
  return capitalize(names[names.length - 1].split(".")[0]);
}

export function isVideo(path) {
  let names = path.split("/");
  return names[names.length - 1].split(".")[1] === "mp4";
}

export function genRandInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getCollectNameById(collects, collect_id) {
  for (let i = 0; i < collects.length; ++i)
    if (collects[i].id === collect_id) return collects[i].name;
  return "";
}

export function resolveImage(src) {
  if (src === undefined || src === null || src === "") return borderBackground;
  return src;
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function setAnchorAttributes(id) {
  let div = document.getElementById(id);
  if (div) {
    let anchors = div.getElementsByTagName("a");
    for (let i = 0; i < anchors.length; ++i) {
      anchors[i].setAttribute("target", "_blank");
      anchors[i].setAttribute("rel", "noopener noreferrer nofollow");
    }
  }
}

export function isSearched(search, field) {
  return (
    search === "" ||
    (Boolean(field) && field.toLowerCase().includes(search.toLowerCase()))
  );
}

export function isPictureLink(src) {
  return PICTURE_LINK_REGEX.test(src)
}

export function displayPrice(price) {
  return formatCurrency(Currency.EUR.key, price);
}

export function displayDate(language, date) {
  let dateMoment = moment(date);
  if (isFrench(language)) {
    dateMoment = dateMoment.locale("fr");
  }
  return dateMoment.format("Do MMMM YYYY");
}

export function displayDateTime(date) {
  return moment(date, true).format("YYYY-MM-DD hh:mm:ss");
}
