import React from "react"
import { CircularProgress, styled } from "@mui/material";
import ActionButton from "component/commons/buttons/ActionButton";
import ScreenVisibilityDetector from "component/commons/ScreenVisibilityDetector";
import useTranslate from "hooks/useTranslate";
import creationsTexts from "translation/texts/creations-texts";

const LoadMoreWrapper = styled("div")(
  () => `
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
`
);

export default function ShowMoreButton({ doneFetching, isLoading, hasResults, callback }) {
  const translate = useTranslate();

  if (isLoading) {
    return (
      <LoadMoreWrapper>
        <CircularProgress color="action" />
      </LoadMoreWrapper>
    );
  } else if (!doneFetching) {
    return (
      <LoadMoreWrapper>
        <ScreenVisibilityDetector onVisible={callback} />
        <ActionButton onClick={callback}>{translate(creationsTexts.SHOW_MORE)}</ActionButton>
      </LoadMoreWrapper>
    );
  } else if (!hasResults) {
    return (
      <LoadMoreWrapper>
        <div style={{ textAlign: "center" }}>{translate(creationsTexts.NO_RESULT)}</div>
      </LoadMoreWrapper>
    );
  } else {
    return <></>;
  }
}
