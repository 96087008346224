import { Grid } from "@mui/material";
import Description from "component/commons/Description";
import SquareImage from "component/commons/images/SquareImage";
import PageContainer from "component/commons/page/PageContainer";
import PageTitle from "component/commons/page/PageTitle";
import useBackHistory from "hooks/useBackHistory";
import useTranslate from "hooks/useTranslate";
import useTranslateDescription from "hooks/useTranslateDescription";
import useTranslateLabel from "hooks/useTranslateLabel";
import CustomSection from "models/CustomSection";
import React, { useEffect, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import { useNavigate } from "react-router-dom";
import API from "services/API";
import pressTexts from "translation/texts/press-texts";
import { bindPicturePath } from "utils/tools";

export default function PressOverview() {
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const translate = useTranslate();
  const translateLabel = useTranslateLabel();
  const translateDescription = useTranslateDescription();

  const [sections, setSections] = useState([]);

  useEffect(() => {
    getSections();
  }, []);

  const getSections = () => {
    API.get("/custom-section", {
      params: {
        sectionType: CustomSection.PRESS.key,
      },
    })
      .then(function (response) {
        setSections(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <PageContainer maxWidth="md">
      <PageTitle>{translateLabel(CustomSection.PRESS)}</PageTitle>
      <Grid
        container
        spacing={10}
        sx={{ textAlign: "center", paddingTop: "3rem" }}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {sections.map((section) => (
          <Grid key={section.id} item xs={12} sm={6} md={4}>
            <div
              className="clickable"
              onClick={() => navigate(`/press/${section.id}`, {...backHistory})}
            >
              <SquareImage
                src={bindPicturePath("custom_sections", section.cover_path)}
              />
            </div>
            <h2>{section.name}</h2>
            <div>
              <Description
                text={translateDescription(section)}
                maxNbLines={5}
                showMoreText={translate(pressTexts.READ_MORE)}
                customAction={() =>
                  navigate(`/press/${section.id}`, {...backHistory})
                }
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
}
