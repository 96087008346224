import React from "react";
import MediaIconLink from "./MediaIconLink";
import { styled } from "@mui/material";

const mediaLinks = [
  {
    icon: "fab fa-youtube",
    link: "https://www.youtube.com/channel/UC7tHS1-tnv7koiHm79Kn30Q",
  },
  {
    icon: "fab fa-facebook-f",
    link: "https://www.facebook.com/Galbert-Couture-Paris-175396579645168/",
  },
  { icon: "fab fa-instagram", link: "https://www.instagram.com/galbertparis/" },
  {
    icon: "fab fa-linkedin-in",
    link: "https://www.linkedin.com/company/theo-galbert-couture-paris/",
  },
];

const MediaIconRow = styled("ul")(
  () => `
  list-style: none;
  display: inline-flex;
  padding-inline-start: 0;
`
);

export default function SocialLinks({ containerStyle, innerPadding }) {
  return (
    <div style={containerStyle}>
      <MediaIconRow>
        {mediaLinks.map((media) => (
          <li
            key={media.icon}
            style={{ textAlign: "center", padding: `0 ${innerPadding}em` }}
          >
            <MediaIconLink href={media.link} icon={media.icon} />
          </li>
        ))}
      </MediaIconRow>
    </div>
  );
}
