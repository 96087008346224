import { Backdrop, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledBackdrop = styled(Backdrop)(
  ({}) => `
  zIndex: 999,
  color: "#fff"
`
);

export default function CircularBackdrop({ open }) {
  return (
    <StyledBackdrop open={open}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
}
