import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import Page from "component/commons/page/Page";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import API from "services/API";
import { cartState, sessionTokenState } from "store/atoms";
import { userSignedInState } from "store/selectors";
import ordersTexts from "translation/texts/orders-texts";
import BasketOverview from "../orders/BasketOverview";
import OrdersOverview from "../orders/OrdersOverview";
import useTranslate from "hooks/useTranslate";
import PageContainer from "component/commons/page/PageContainer";

export default function OrderPage() {
  const [cart, setCart] = useRecoilState(cartState);
  const sessionToken = useRecoilValue(sessionTokenState);
  const userSignedIn = useRecoilValue(userSignedInState);
  const query = useQuery();
  const translate = useTranslate();

  const [orders, setOrders] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProperties, setSnackbarProperties] = useState({
    success: false,
    email: "",
  });

  useEffect(() => {
    if (userSignedIn) {
      fetchOrders();
    }
    checkAndDisplayPopup();
  }, [userSignedIn]);

  const checkAndDisplayPopup = () => {
    const success = query.get("success");
    const cancelled = query.get("cancelled");

    if (success) {
      setCart([]);
      setSnackbarProperties({ success: true });
      setSnackbarOpen(true);
    } else if (cancelled) {
      setSnackbarProperties({ success: false });
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarProperties({ success: false, email: "" });
  };

  const fetchOrders = () => {
    API({
      method: "GET",
      url: "/order",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function (response) {
        setOrders(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const placeOrder = () => {
    const items = cart.map((item) => ({
      product_id: item.product.id,
      size: item.size,
      color: item.color,
    }));

    API({
      method: "POST",
      url: "/order",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: {
        items,
      },
    })
      .then(function (response) {
        if (response.data.url) {
          window.location.replace(response.data.url);
        } else {
          // TODO: pop up error
          console.warn(response.data);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Page title="Orders" endpoint="orders" variant="lightBackground">
      <PageContainer maxWidth="lg">
        {Boolean(cart.length) && <BasketOverview placeOrder={placeOrder} />}
        {Boolean(orders.length) && <OrdersOverview orders={orders} />}
      </PageContainer>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={snackbarProperties.success ? 6000 : 4000}
      >
        <Alert
          severity={snackbarProperties.success ? "success" : "info"}
          onClick={handleSnackbarClose}
        >
          {snackbarProperties.success
            ? translate(ordersTexts.ORDER_SUCESS)
            : translate(ordersTexts.ORDER_CANCELLED)}
        </Alert>
      </Snackbar>
    </Page>
  );
}
