import React from "react";
import { styled } from "@mui/material";
import { useRecoilState } from "recoil";
import { languageState } from "store/atoms";
import Language from "models/Language";

const SelectedLanguage = styled("span")(
  ({ theme }) => `
  color: ${theme.palette.action.active};
`
);

const NotSelectedLanguage = styled("span")(
  ({ theme }) => `
  &:hover {
    color: ${theme.palette.action.active};
    cursor: pointer;
  }
`
);

export default function LanguageSwitch() {
  const [language, setLanguage] = useRecoilState(languageState);

  const LanguageComponent = ({ languageRef }) => {
    return language.key === languageRef.key ? (
      <SelectedLanguage onClick={() => setLanguage(languageRef)}>
        {languageRef.key}
      </SelectedLanguage>
    ) : (
      <NotSelectedLanguage onClick={() => setLanguage(languageRef)}>
        {languageRef.key}
      </NotSelectedLanguage>
    );
  };

  return (
    <div style={{ fontSize: "larger" }}>
      <LanguageComponent languageRef={Language.EN} /> |{" "}
      <LanguageComponent languageRef={Language.FR} />
    </div>
  );
}
