import LoadingPage from "component/pages/LoadingPage";
import useAuthorization from "hooks/useAuthorization";
import useLogin from "hooks/useLogin";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userSignedInState } from "store/selectors";

export default function SecureComponent({ children, authorization }) {
  const { isAuthorized } = useAuthorization();
  const isUserSignedIn = useRecoilValue(userSignedInState);
  const isLoadingLogin = useLogin();
  const [isLoading, setLoading] = useState(isLoadingLogin || !isUserSignedIn)

  useEffect(() => {
    if (!isLoadingLogin && isLoading) {
      if (isUserSignedIn) {
        setLoading(false);
      } else {
        const loadingTimeout = setTimeout(() => {
          setLoading(false);
        }, 500);
        return () => clearTimeout(loadingTimeout)
      }
    }
  }, [isLoadingLogin, isUserSignedIn])

  if (isLoading) {
    return <LoadingPage />
  } else if (isUserSignedIn) {
    return isAuthorized(authorization) ? (
      children
    ) : (
      <Navigate to="/unauthorized" />
    );
  } else {
    return <Navigate to="/login" />
  }
}
