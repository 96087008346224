import colors from "./_colors.scss";

/* Select input */
export const SELECT_INPUT_HEIGHT = 48;
export const SELECT_INPUT_PADDING_TOP = 8;
export const SELECT_INPUT_WIDTH = 250;

export const MINIATURE_SIZE = 75;
export const PICTURE_SIZE = 130;
export const GRID_ELEMENT_SIZE = 300;

/* Helper classes */
export const root = {
  flexGrow: 1,
};
export const flex = {
  display: "flex",
};
export const center = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
};
export const verticallyCenter = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
export const verticalAlign = {
  verticalAlign: "sub",
};
export const verticalAlignItems = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const padding = {
  padding: 20,
};
export const paddingContainer = {
  paddingTop: 1,
  paddingBottom: 21,
};
export const margin = {
  margin: 10,
};
export const description = {
  whiteSpace: "pre-wrap",
};
export const noDecoration = {
  textDecorationLine: "none",
};
export const blackBackground = {
  backgroundColor: "#000",
  color: "#fff",
};
export const redBackground = {
  backgroundColor: colors.red,
  color: "#fff",
};
export const greenBackground = {
  backgroundColor: colors.green,
  color: "#fff",
};
export const whiteColor = {
  color: "#fff",
};
export const disabledColor = {
  color: `${colors.weakFont1} !important`,
};
export const rounded = {
  borderRadius: "50%",
};
export const shadow = {
  boxShadow: "3px 3px 3px grey",
};
export const left = {
  float: "left",
};
export const right = {
  float: "right",
};
export const fullWidth = {
  width: "100%",
};
export const largerText = {
  fontSize: "larger",
};
export const capWidthHeight = {
  maxWidth: "100%",
  maxHeight: "80vh",
};

/* Elements */
export const withTextLink = {
  textAlign: "center",
  color: "#000",
  textDecorationLine: "none",
  "&:hover": {
    color: colors.primary,
  },
};
export const smallListItem = {
  display: "inline-block",
  width: MINIATURE_SIZE,
  margin: 5,
};
export const mediumListItem = {
  display: "inline-block",
  width: PICTURE_SIZE,
  margin: 5,
};
export const miniaturePicture = {
  width: MINIATURE_SIZE,
  height: MINIATURE_SIZE,
  objectFit: "cover",
  objectPosition: "center",
  overflow: "hidden",
};
export const gridList = {
  listStyle: "none",
  display: "inline-block",
  padding: 0,
};
export const gridElement = {
  height: "auto",
  maxWidth: "100%",
  display: "inline-block",
  margin: 5,
};
export const gridItem = {
  maxWidth: GRID_ELEMENT_SIZE,
  maxHeight: GRID_ELEMENT_SIZE,
};
export const popover = {
  width: 300,
  maxWidth: "100%",
  border: "1px solid " + colors.secondary,
  borderRadius: 5,
  backgroundColor: "#fff",
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 10,
};
export const SelectInputProps = {
  PaperProps: {
    style: {
      maxHeight: SELECT_INPUT_HEIGHT * 4.5 + SELECT_INPUT_PADDING_TOP,
      width: SELECT_INPUT_WIDTH,
    },
  },
};
export const fabBtn = {
  position: "fixed",
  bottom: 20,
  right: 20,
};
export const link = {
  color: colors.primary,
  textDecorationLine: "none",
  "&:hover": {
    color: colors.secondary,
    cursor: "pointer",
  },
};
export const promotion = {
  textAlign: "center",
  padding: 10,
  border: "1px solid black",
  boxShadow: "2px 2px 5px grey",
  textTransform: "uppercase",
};
export const infoIcon = {
  fontSize: "1.5em",
  marginRight: "1em",
};
