import { Grid, TextField } from "@mui/material";
import React from "react";
import { getHelperText } from "../utilities";

export default function AdminTextInput({ data, value, setValue }) {
  const helperText = getHelperText(data, value);
  
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <TextField
        fullWidth={true}
        label={data.label}
        value={value}
        onChange={(e) => setValue(data.key, e.target.value)}
        inputProps={{
          maxLength: data.maxLength ? data.maxLength : null,
        }}
        type={data.type}
        multiline={data.multiline}
        error={Boolean(helperText)}
        helperText={helperText}
      />
    </Grid>
  );
}