import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, styled } from "@mui/material";
import React from "react";

const StyledSelect = styled(Select)(({theme}) => ({
  "&.Mui-disabled": {
    color: theme.palette.text.secondary,
    WebkitTextFillColor: theme.palette.text.secondary
  }
}))

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  color: theme.palette.background.primary
}))

export default function AdminSelectMenu({ data, value, setValue }) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <FormControl fullWidth>
        <InputLabel id={`${data.key}-label`}>{data.label}</InputLabel>
        <StyledSelect
          labelId={`${data.key}-label`}
          value={value}
          label={data.label}
          onChange={(e) => setValue(data.key, e.target.value)}
        >
          {Boolean(data.items) && data.items.map((item) => (
            <StyledMenuItem key={item.text} value={item.value}>{item.text}</StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Grid>
  );
}
