import React from "react";
import Description from "component/commons/Description";
import useTranslateDescription from "hooks/useTranslateDescription";

export default function ShippingInfo() {
  const translateDescription = useTranslateDescription();

  let shippingInfo = {};

  shippingInfo.description = (
    <div>
      <div>
        <b>FREE SHIPPING</b>
        <div>
          We offer free delivery on all orders over 1000€ (limited to European
          Union)
        </div>
      </div>
      <br />
      <div>
        <b>DELIVERY</b>
        <div>
          FRANCE: 1-3 working days
          <br />
          EUROPE: 5-6 working days
          <br />
          REST OF THE WORLD: 7-21 working days
        </div>
      </div>
      <br />
      <a href="/shipping-policy" target="_blank">
        More info
      </a>
    </div>
  );

  shippingInfo.description_fr = (
    <div>
      <div>
        <b>LIVRAISON GRATUITE</b>
        <div>
          Nous offrons la livraison gratuite pour toutes les commandes de plus
          de 1000€ (limitée à l'Union européenne)
        </div>
      </div>
      <br />
      <div>
        <b>LIVRAISON</b>
        <div>
          FRANCE : 1-3 jours ouvrables
          <br />
          EUROPE : 5-6 jours ouvrables
          <br />
          RESTE DU MONDE : 7-21 jours ouvrables
        </div>
      </div>
      <br />
      <a href="/shipping-policy">Voir plus</a>
    </div>
  );

  return <Description text={translateDescription(shippingInfo)} />;
}
