import { useState } from "react";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";

export default function useBackendService(props) {
  const sessionToken = useRecoilValue(sessionTokenState);
  const withSessionToken = props?.withSessionToken;

  const [loadingCount, setLoadingCount] = useState(0);

  const apiCall = async ({ method, url, headers, data, params }) => {
    setLoadingCount((prevValue) => prevValue + 1);
    try {
      return await API({
        method,
        url,
        headers: {
          Authorization: withSessionToken ? `Bearer ${sessionToken}` : undefined,
          ...headers,
        },
        data,
        params,
      });
    } finally {
      setLoadingCount((prevValue) => prevValue - 1);
    }
  };

  return [apiCall, loadingCount !== 0];
}
