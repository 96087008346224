import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import ContainedImage from "component/commons/images/ContainedImage";
import useBackHistory from "hooks/useBackHistory";

const ImageLink = styled(Link)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${theme.palette.action.active}
  }
`
);

export default function ProductMiniature({ name, src, to }) {
  const backHistory = useBackHistory();

  return (
    <div style={{ textAlign: "center" }}>
      <ImageLink to={to} state={{...backHistory.state}}>
        <ContainedImage
          alt={name}
          src={src}
        />
        <div>{name}</div>
      </ImageLink>
    </div>
  );
}
