import { useTheme } from "@mui/material";
import React from "react";

export default function FlatDot({ active, onClick }) {
  const theme = useTheme();

  return (
    <div
      style={{
        width: "50px",
        height: "2px",
        padding: "0px",
        backgroundColor: theme.palette.text.primary,
        opacity: active ? 1 : 0.5,
      }}
      onClick={onClick}
    />
  );
}
