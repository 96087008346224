import AdminCheckbox from "./AdminCheckbox";
import AdminPictureUpload from "./AdminPictureUpload";
import AdminSelectMenu from "./AdminSelectMenu";
import AdminTextArea from "./AdminTextArea";
import AdminTextInput from "./AdminTextInput";

const AdminFormType = {
  CHECKBOX: AdminCheckbox,
  TEXT_INPUT: AdminTextInput,
  SELECT_MENU: AdminSelectMenu,
  TEXT_AREA: AdminTextArea,
  PICTURE_UPLOAD: AdminPictureUpload,
}

export default AdminFormType;