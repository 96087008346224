import { styled } from "@mui/material";
import TextSize from "../typography/TextSize";

const ClassicButton = styled("button")(({theme}) => ({
  padding: 5,
  background: "transparent",
  borderRadius: 3,
  cursor: "pointer",
  fontSize: TextSize.VERY_LARGE + "rem",
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.text.primary}`,

  "&:hover": {
    color: theme.palette.action.active,
    border: `1px solid ${theme.palette.action.active}`,
  }
}))

export default ClassicButton;