import React from "react";
import { Tooltip } from "@mui/material";
import ActionButton from "./ActionButton";

export default function TooltipButton({ tooltip, onClick, children, className, style }) {
  return (
    <div>
      <Tooltip
        title={tooltip}
        disableFocusListener={!tooltip}
        disableHoverListener={!tooltip}
        disableTouchListener={!tooltip}
        enterTouchDelay={0}
        enterDelay={200}
        placement="top"
        arrow
      >
        <span>
          <ActionButton
            onClick={tooltip ? null : onClick}
            enabled={tooltip ? "false" : "true"}
            className={className}
            style={style}
          >
            {children}
          </ActionButton>
        </span>
      </Tooltip>
    </div>
  );
}
