import React from "react";
import { Container, Grid, styled } from "@mui/material";
import Logo from "component/commons/Logo";
import { loadCSS } from "fg-loadcss";
import { useEffect } from "react";
import { SMALL_FONT_SIZE } from "utils/constants";
import footerTexts from "translation/texts/footer-texts";
import { Link } from "react-router-dom";
import CustomSection from "models/CustomSection";
import Separator from "component/commons/Separator";
import LanguageSwitch from "./LanguageSwitch";
import SocialLinks from "./SocialLinks";
import useTranslateLabel from "hooks/useTranslateLabel";
import useTranslate from "hooks/useTranslate";

const Background = styled("div")(
  ({ theme }) => `
  background-color: ${theme.palette.background.secondary};
  color: ${theme.palette.text.primary};
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 1rem;
`
);

const LinkList = styled("ul")(
  () => `
  list-style: none;
  padding: 0;
  margin-top: 0;
`
);

const FooterLink = styled("a")(
  ({ theme }) => `
  text-decoration: none;
  color: ${theme.palette.text.secondary};
  &:hover {
    color: ${theme.palette.action.active};
    cursor: pointer;
  }
`
);

export default function Footer() {
  const translate = useTranslate();
  const translateLabel = useTranslateLabel();

  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  return (
    <Background>
      <Container maxWidth="md">
        <Link to="/">
          <Logo size={35} />
        </Link>

        <SocialLinks containerStyle={{ paddingTop: "1em" }} innerPadding={2} />

        <Grid container justifyContent="center" style={{ textAlign: "start" }}>
          <Grid item xs={12} sm={4}>
            <h4>{translate(footerTexts.LEGAL)}</h4>
            <LinkList>
              <li>
                <FooterLink href={`/${CustomSection.SHIPPING.endpoint}`}>
                  {translateLabel(CustomSection.SHIPPING)}
                </FooterLink>
              </li>
              <li>
                <FooterLink href={`/${CustomSection.TERMS.endpoint}`}>
                  {translateLabel(CustomSection.TERMS)}
                </FooterLink>
              </li>
              <li>
                <FooterLink href={`/${CustomSection.CERTIFS.endpoint}`}>
                  {translateLabel(CustomSection.CERTIFS)}
                </FooterLink>
              </li>
            </LinkList>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h4>{translate(footerTexts.NEWS)}</h4>
            <LinkList>
              <li>
                <FooterLink href={`/${CustomSection.PRESS.endpoint}`}>
                  {translateLabel(CustomSection.PRESS)}
                </FooterLink>
              </li>
            </LinkList>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h4>{translate(footerTexts.COMPANY)}</h4>
            <LinkList>
              <li>
                <FooterLink href={`/${CustomSection.ABOUT.endpoint}`}>
                  {translateLabel(CustomSection.ABOUT)}
                </FooterLink>
              </li>
              <li>
                <FooterLink href="/contact">
                  {translate(footerTexts.CONTACT)}
                </FooterLink>
              </li>
              <li>
                <FooterLink href={`/${CustomSection.SUSTAIN.endpoint}`}>
                  {translateLabel(CustomSection.SUSTAIN)}
                </FooterLink>
              </li>
            </LinkList>
          </Grid>
        </Grid>

        <Separator color="grey" fullWidth={true} />

        <Grid
          container
          style={{ fontSize: SMALL_FONT_SIZE, marginTop: "0.5rem" }}
        >
          <Grid item xs={12} sm={4}>
            <div style={{ float: "left" }}>
              Copyright &copy; 2021 Galbert Paris.{" "}
              {translate(footerTexts.ALL_RIGHTS_RESERVED)}
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LanguageSwitch />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ float: "right" }}>
              <FooterLink href={`/${CustomSection.PRIVACY.endpoint}`}>
                {translateLabel(CustomSection.PRIVACY)}
              </FooterLink>
              &nbsp;|&nbsp;
              <FooterLink href={`/${CustomSection.TERMS.endpoint}`}>
                {translateLabel(CustomSection.TERMS)}
              </FooterLink>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Background>
  );
}
