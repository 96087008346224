import { selector } from "recoil";

const { userState, cartState } = require("./atoms");

export const userRoleState = selector({
  key: "userRoleState",
  get: ({ get }) => {
    const user = get(userState);
    return Boolean(user) ? user.role : null;
  },
});

export const userSignedInState = selector({
  key: "userSignedInState",
  get: ({ get }) => {
    const user = get(userState);
    return Boolean(user);
  },
});

export const cartItemsCountState = selector({
  key: "cartItemsCountState",
  get: ({ get }) => {
    const cart = get(cartState);
    return cart.length;
  },
});

export const cartSubtotalState = selector({
  key: "cartSubtotalState",
  get: ({ get }) => {
    const cart = get(cartState);
    return cart.reduce((sum, item) => {
      return sum + item.product.price;
    }, 0);
  },
});
