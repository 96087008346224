import { styled } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";

const ShowMore = styled("p")(({ theme }) => ({
  width: "fit-content",
  color: theme.palette.action.active,
  textAlign: "start",
  "&:hover": {
    cursor: "pointer",
  },
}));

const descriptionBasedStyle = ({ theme }) => ({
  whiteSpace: "pre-wrap",
  "& a": {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.action.active,
      cursor: "pointer",
    },
  },
});

const FullSizeDescription = styled("div")(({ theme }) => descriptionBasedStyle({ theme }));

const ShortenedDescription = styled("div")(({ theme }) => ({
  ...descriptionBasedStyle({ theme }),
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
}));

export default function Description({ text, maxNbLines, showMoreText, customAction }) {
  const descriptionRef = createRef();

  const [isShowMore, setShowMore] = useState(!maxNbLines);
  const [isShowMoreEnabled, setShowMoreEnabled] = useState(false);

  useEffect(() => {
    setShowMore(!maxNbLines);
    setShowMoreEnabled(false);
  }, [text, maxNbLines]);

  useEffect(() => {
    if (descriptionRef && descriptionRef.current) {
      if (
        showMoreText &&
        maxNbLines &&
        descriptionRef.current.clientHeight < descriptionRef.current.scrollHeight
      ) {
        setShowMoreEnabled(true);
      }

      setAnchorAttributes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionRef]);

  const setAnchorAttributes = () => {
    let anchors = descriptionRef.current.getElementsByTagName("a");
    for (let j = 0; j < anchors.length; ++j) {
      anchors[j].setAttribute("target", "_blank");
      anchors[j].setAttribute("rel", "noopener noreferrer nofollow");
    }
  };

  const onShowMore = () => {
    if (customAction) {
      customAction();
    } else {
      setShowMoreEnabled(false);
      setShowMore(true);
    }
  };

  const DescriptionComponent = ({ refComponent, style, dangerouslySetInnerHTML, children }) => {
    return isShowMore ? (
      <FullSizeDescription
        ref={refComponent}
        style={style}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      >
        {children}
      </FullSizeDescription>
    ) : (
      <ShortenedDescription
        ref={refComponent}
        style={style}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      >
        {children}
      </ShortenedDescription>
    );
  };

  const getHtmlText = () => {
    return typeof text === "string" ? { __html: text } : null;
  };

  const getChildrenComponent = () => {
    return typeof text === "string" ? null : text;
  };

  return (
    <>
      <DescriptionComponent
        refComponent={descriptionRef}
        style={
          isShowMore
            ? {}
            : {
                WebkitLineClamp: maxNbLines,
                lineClamp: maxNbLines,
              }
        }
        dangerouslySetInnerHTML={getHtmlText()}
      >
        {getChildrenComponent()}
      </DescriptionComponent>
      {isShowMoreEnabled && <ShowMore onClick={onShowMore}>{showMoreText}</ShowMore>}
    </>
  );
}
