export function getHelperText(formElement, value) {
  if (formElement.mandatory && !value) {
    return `${formElement.label} is mandatory`;
  } else {
    return "";
  }
}

export function getUpdatedCoverPath(pictures, currentCoverPath) {
  let cover_path = currentCoverPath;
  let previous_picture = null;
  if (pictures?.length) {
    previous_picture = cover_path;
    cover_path = pictures[0].name;
  }
  return { cover_path, previous_picture };
}
