import React from "react";
import Page from "component/commons/page/Page";
import "react-medium-image-zoom/dist/styles.css";
import { Outlet } from "react-router-dom";
import headerTexts from "translation/texts/header-texts";
import useTranslate from "hooks/useTranslate";

export default function PressPage() {
  const translate = useTranslate();

  return (
    <Page
      title={translate(headerTexts.PRESS)}
      endpoint="press"
      variant="lightBackground"
    >
      <Outlet />
    </Page>
  );
}
