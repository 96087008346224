import React from "react";
import { Grid, styled } from "@mui/material";
import { useRecoilValue } from "recoil";
import { cartItemsCountState, cartSubtotalState } from "store/selectors";
import ordersTexts from "translation/texts/orders-texts";
import BasketItems from "./BasketItems";
import { displayPrice } from "utils/tools";
import TooltipButton from "component/commons/buttons/TooltipButton";
import useTranslate from "hooks/useTranslate";
import PageTitle from "component/commons/page/PageTitle";
import TextElement from "component/commons/typography/TextElement";
import TextSize from "component/commons/typography/TextSize";

const COMING_SOON = {
  EN: "Coming soon",
  FR: "Bientôt disponible",
};

const ScrollableGrid = styled(Grid)(() => ({
  maxHeight: "80vh",
    scrollBehavior: "smooth",
    overflowY: "scroll",
    overflowX: "hidden",
    padding: "1rem",
}))

const RecapTable = styled("table")(() => ({
  width: "100%",
  textTransform: "uppercase",
  "& tr": {
    "& > td": {
      "&:first-of-type": {
        textAlign: "left",
      },
      "&:last-of-type": {
        textAlign: "right",
      },
    },
    "&:last-of-type > td": {
      fontWeight: "bold",
      padding: "1rem 0",
    },
  },
}));

export default function BasketOverview({ placeOrder }) {
  const translate = useTranslate();
  const cartItemsCount = useRecoilValue(cartItemsCountState);
  const cartSubtotal = useRecoilValue(cartSubtotalState);

  return (
    <>
      <PageTitle>
        {translate(ordersTexts.BASKET)}{" "}
        <TextElement Tag="span" size={TextSize.SMALL}>
          ({cartItemsCount})
        </TextElement>
      </PageTitle>
      <Grid container>
        <ScrollableGrid item xs={12} sm={6}>
          <BasketItems />
        </ScrollableGrid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ padding: "0 5rem", textAlign: "center" }}
        >
          <RecapTable>
            <tbody>
              <tr>
                <td>{translate(ordersTexts.SUBTOTAL)}</td>
                <td>{displayPrice(cartSubtotal)}</td>
              </tr>
              <tr>
                <td>{translate(ordersTexts.DELIVERY)}</td>
                <td>{translate(ordersTexts.FREE)}</td>
              </tr>
              <tr>
                <td>{translate(ordersTexts.VAT)}</td>
                <td>{displayPrice(0)}</td>
              </tr>
              <tr>
                <td>{translate(ordersTexts.TOTAL)}</td>
                <td>{displayPrice(cartSubtotal)}</td>
              </tr>
            </tbody>
          </RecapTable>
          <TooltipButton
            tooltip={translate(COMING_SOON)}
            style={{ width: "100%" }}
          >
            {translate(ordersTexts.PLACE_ORDER)}
          </TooltipButton>
        </Grid>
      </Grid>
    </>
  );
}
