import { Collapse, Container, Grid, IconButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";
import ordersTexts from "translation/texts/orders-texts";
import OrderItem from "./OrderItem";
import OrderTable from "./OrderTable";
import useTranslate from "hooks/useTranslate";

export default function OrdersOverview({ orders }) {
  const sessionToken = useRecoilValue(sessionTokenState);
  const translate = useTranslate();

  const [orderDetails, setOrderDetails] = useState({});
  const [collapsedId, setCollapsedId] = useState(null);

  useEffect(() => {
    updateOrderDetails();
  }, [orders]);

  const updateOrderDetails = () => {
    API({
      method: "GET",
      url: "/order/items",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function (response) {
        let detailsTmp = {};

        response.data.forEach((orderItem) => {
          if (!detailsTmp[orderItem.order_id]) {
            detailsTmp[orderItem.order_id] = [];
          }
          detailsTmp[orderItem.order_id].push(orderItem);
        });

        setOrderDetails(detailsTmp);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleCollapse = (orderId) => {
    if (collapsedId === orderId) {
      setCollapsedId(null);
    } else {
      setCollapsedId(orderId);
    }
  };

  return (
    <Container maxWidth="lg">
      <h1>
        {translate(ordersTexts.MY_ORDERS)} <span>({orders.length})</span>
      </h1>

      {orders.map((order) => (
        <div key={order.id}>
          <OrderTable order={order} />
          <span>{translate(ordersTexts.SEE_ORDER_ITEMS)}</span>
          <IconButton onClick={() => handleCollapse(order.id)} size="large">
            {collapsedId === order.id ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Collapse in={collapsedId === order.id}>
            <Grid
              container
              justifyContent="center"
              alignItems="flex-end"
              spacing={2}
            >
              {Boolean(orderDetails[order.id]?.length) &&
                orderDetails[order.id].map((item) => (
                  <OrderItem key={item.id} item={item} />
                ))}
            </Grid>
          </Collapse>
        </div>
      ))}
    </Container>
  );
}
