import AddButton from "component/commons/buttons/AddButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";
import { INSERT_ID } from "utils/constants";
import AdminTable from "../commons/AdminTable";
import useBackHistory from "hooks/useBackHistory";
import Toaster, { useToaster } from "component/commons/Toaster";

const TABLE_HEADERS = ["ID", "Name"];

export default function CollectionTable() {
  const navigate = useNavigate(); 
  const backHistory = useBackHistory()
  const sessionToken = useRecoilValue(sessionTokenState);

  const [collectionTableData, setCollectionTableData] = useState([]);
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster()

  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = () => {
    API.get("/collection")
      .then(function (response) {
        let tableData = []
        response.data.forEach(collection => tableData.push({
          id: collection.id,
          cells: [collection.id, collection.name],
        }))
        setCollectionTableData(tableData);
      })
      .catch(function (error) {
        openToaster(error.toString())
        console.error(error);
      });
  };

  const editCollection = (collectionId) => {
    navigate(encodeURI(collectionId), {...backHistory})
  }

  const deleteCollection = (collectionId) => {
    API({
      method: "DELETE",
      url: `/collection/${collectionId}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function () {
        setCollectionTableData(collectionTableData.filter(collection => collection.id !== collectionId))
      })
      .catch(function (error) {
        openToaster(error.toString())
        console.error(error);
      });
  };

  return (
    <>
      <AdminTable
        name="collection"
        headers={TABLE_HEADERS}
        rows={collectionTableData}
        editAction={editCollection}
        deleteAction={deleteCollection}
      />
      <AddButton onClick={() => editCollection(INSERT_ID)} />
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}