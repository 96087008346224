import React from "react";
import "style/menu-button.scss";

export default function MenuButton({ handleClick, checked }) {
  return (
    <div className="menu-icon">
      <input
        className="menu-icon__checkbox"
        type="checkbox"
        checked={checked}
        onChange={(e) => handleClick(e.target.checked)}
      />
      <div>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
