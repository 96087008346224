import useBackHistory from "hooks/useBackHistory";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { INSERT_ID } from "utils/constants";
import AdminEditForm from "../commons/form/AdminEditForm";
import AdminFormBuilder from "../commons/form/AdminFormBuilder";
import AdminFormType from "../commons/form/AdminFormType";
import useBackendService from "hooks/useBackendService";
import Toaster, { useToaster } from "component/commons/Toaster";
import LoadingPage from "component/pages/LoadingPage";
import {getUpdatedCoverPath} from "../commons/utilities"

export default function CollectionEdit() {
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  const [coverPath, setCoverPath] = useState(null);
  const [collectionFormData, setCollectionFormData] = useState(buildForm({}));

  useEffect(() => {
    if (collectionId && collectionId !== INSERT_ID) {
      getCollection();
    }
  }, [collectionId]);

  const getCollection = () => {
    apiCall({
      method: "GET",
      url: `/collection/${collectionId}`,
    })
      .then((response) => {
        setCoverPath(response.data.cover_path);
        setCollectionFormData(buildForm(response.data));
      })
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const updateCollection = (formValues) => {
    const { cover_path, previous_picture } = getUpdatedCoverPath(formValues.cover.uploads, coverPath);
    return apiCall({
      method: collectionId === INSERT_ID ? "POST" : "PUT",
      url: "/collection",
      data: {
        id: collectionId,
        name: formValues.name,
        description: formValues.description,
        description_fr: formValues.description_fr,
        cover_path,
        previous_picture,
      },
    });
  };

  const uploadCover = (id, cover) => {
    let data = new FormData();
    data.append("collect_cover", cover, cover.name);
    return apiCall({
      method: "POST",
      url: `/upload/collection/${id}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
  };

  const submitForm = async (formValues) => {
    if (collectionId === INSERT_ID) {
      try {
        // sync: wait for insert ID to upload cover
        const response = await updateCollection(formValues);
        const { insertId } = response.data;
        if (insertId) {
          if (formValues.cover.uploads?.length) {
            await uploadCover(insertId, formValues.cover.uploads[0]);
          }
          navigate("../" + insertId, { replace: true, ...backHistory });
        }
      } catch (error) {
        openToaster(error.toString());
        console.error(error);
      }
    } else {
      // async: parallelize update and upload
      let promises = [updateCollection(formValues)];
      if (formValues.cover.uploads?.length) {
        promises.push(uploadCover(collectionId, formValues.cover.uploads[0]));
      }
      Promise.all(promises)
        .then(() => getCollection())
        .catch((error) => {
          openToaster(error.toString());
          console.error(error);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <AdminEditForm formData={collectionFormData} onSubmit={submitForm} />
      )}
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}

function buildForm(collection) {
  let form = [];
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("name")
      .withLabel("Name")
      .withInitialValue(collection.name)
      .withDefaultValue("")
      .withMaxLength(48)
      .makeMandatory()
      .build()
  );
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_AREA)
      .withKey("description")
      .withLabel("Description")
      .withInitialValue(collection.description)
      .withDefaultValue("")
      .build()
  );
  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_AREA)
      .withKey("description_fr")
      .withLabel("Description FR")
      .withInitialValue(collection.description_fr)
      .withDefaultValue("")
      .build()
  );

  let pictures = collection.cover_path ? [collection.cover_path] : [];
  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("cover")
      .withLabel("Cover Picture")
      .withFolder("collects/" + collection.id)
      .withInitialValue({ pictures })
      .withDefaultValue({})
      .build()
  );
  return form;
}
