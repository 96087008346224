import { Fade, Popper, styled } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import React, { useState } from "react";
import creationsTexts from "translation/texts/creations-texts";
import headerTexts from "translation/texts/header-texts";
import SimpleButton from "component/commons/buttons/SimpleButton";
import TextSize from "component/commons/typography/TextSize";
import TextFont from "component/commons/typography/TextFont";
import useTranslate from "hooks/useTranslate";
import useTranslateLabel from "hooks/useTranslateLabel";
import TextElement from "component/commons/typography/TextElement";
import useQuery from "hooks/useQuery";

const GENDERS = [
  { key: "male", label: "Man", label_fr: "Masculin" },
  { key: "female", label: "Woman", label_fr: "Féminin" },
];

const ORDERS = [
  {key: "NEWEST", label: "Newest", label_fr: "Plus récent"},
  {key: "OLDEST", label: "Oldest", label_fr: "Moins récent"},
  { key: "PRICE_ASC", label: "Price increasing", label_fr: "Prix croissant" },
  {
    key: "PRICE_DESC",
    label: "Price decreasing",
    label_fr: "Prix décroissant",
  },
];

const FilterListPopper = styled(Popper)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  border: `2px solid ${theme.palette.text.secondary}`,
  padding: "0.5rem",
  "& > ul": {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
}));

const ListElement = styled("li")(({ theme, active }) => ({
  color: active ? theme.palette.action.active : theme.palette.text.secondary,
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.action.active,
    opacity: active ? 0.6 : 1,
  },
}));

const ListSeparator = styled("li")(() => ({
  height: "0.5rem",
}));

const popperModifiers = [
  {
    name: "flip",
    enabled: false,
  },
  {
    name: "offset",
    options: {
      offset: [1, 10],
    },
  },
];

export default function ProductFilters({
  onFilterUpdate,
  collections,
}) {
  const translate = useTranslate();
  const translateLabel = useTranslateLabel();
  const query = useQuery();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "filters-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <SimpleButton aria-describedby={id} onClick={handleClick}>
        <TextElement Tag="span" size={TextSize.LARGE} style={{ verticalAlign: "super" }}>
          {translate(creationsTexts.FILTERS)}
        </TextElement>
        &nbsp;
        <FilterList />
      </SimpleButton>

      <FilterListPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        modifiers={popperModifiers}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ul>
              <li>
                <TextElement Tag="span" size={TextSize.LARGE} font={TextFont.CENTURY}>
                  {translate(headerTexts.COLLECTIONS)}
                </TextElement>
              </li>
              {collections.map((collect) => (
                <ListElement
                  key={collect.id}
                  active={query.get("collection") === collect.id.toString()}
                  onClick={() => onFilterUpdate("collection", collect.id)}
                >
                  {collect.name}
                </ListElement>
              ))}

              <ListSeparator />

              <li>
                <TextElement Tag="span" size={TextSize.LARGE} font={TextFont.CENTURY}>
                  {translate(creationsTexts.GENDER)}
                </TextElement>
              </li>
              {GENDERS.map((gender) => (
                <ListElement
                  key={gender.key}
                  active={query.get("gender") === gender.key}
                  onClick={() => onFilterUpdate("gender", gender.key)}
                >
                  {translateLabel(gender)}
                </ListElement>
              ))}

              <ListSeparator />

              <li>
                <TextElement Tag="span" size={TextSize.LARGE} font={TextFont.CENTURY}>
                  {translate(creationsTexts.ORDER)}
                </TextElement>
              </li>
              {ORDERS.map((order) => (
                <ListElement
                  key={order.key}
                  active={query.get("order") === order.key}
                  onClick={() => onFilterUpdate("order", order.key)}
                >
                  {translateLabel(order)}
                </ListElement>
              ))}
            </ul>
          </Fade>
        )}
      </FilterListPopper>
    </>
  );
}
