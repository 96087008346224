export default {
  CONTACT_US: {
    EN: "Contact us",
    FR: "Contactez nous",
  },
  SUBTEXT: {
    EN:
      'If you have any queries or if we can be any of further assistance please do not hesitate to contact us by email at <a href="mailto:contact@galbertparis.com">contact@galbertparis.com</a>.<br/>' +
      "<br/>For your conveniance GALBERT&copy; offers global assistance in a 24 hours delay.",
    FR:
      'N\'hésitez pas à nous contacter par mail à <a href="mailto:contact@galbertparis.com">contact@galbertparis.com</a> pour tout type de requête.<br/>' +
      "<br/>Pour votre confort, GALBERT&copy; s'engage à fournir une réponse dans un délai de 24 heures.",
  },
};
