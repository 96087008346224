import { Grid } from "@mui/material";
import BackButton from "component/commons/buttons/BackButton";
import Description from "component/commons/Description";
import ContainedImage from "component/commons/images/ContainedImage";
import PageContainer from "component/commons/page/PageContainer";
import PageTitle from "component/commons/page/PageTitle";
import useTranslateDescription from "hooks/useTranslateDescription";
import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useParams } from "react-router-dom";
import API from "services/API";
import { bindPicturePath } from "utils/tools";

export default function PressArticle() {
  const { sectionId } = useParams();
  const translateDescription = useTranslateDescription();

  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (sectionId) {
      const getArticle = () => {
        API.get(`/custom-section/${sectionId}`)
          .then(function (response) {
            setArticle(response.data);
          })
          .catch(function (error) {
            console.error(error);
          });
      };
      getArticle();
    }
  }, [sectionId]);

  return (
    <>
      <BackButton />
      {Boolean(article) && (
        <PageContainer maxWidth="md">
          <PageTitle>{article.name}</PageTitle>
          {Boolean(article.cover_path) && (
            <div>
              <Zoom>
                <ContainedImage
                  src={bindPicturePath("custom_sections", article.cover_path)}
                />
              </Zoom>
            </div>
          )}
          <div style={{ margin: "2rem 0" }}>
            <Description
              text={translateDescription(article)}
              maxNbLines={10}
              showMoreText="More details +"
            />
          </div>
          {Boolean(article.medias.length) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {article.medias.map((picture) => (
                <Grid item key={picture} xs={12} sm={6} md={4} style={{padding: 10}}>
                  <Zoom>
                    <img
                      alt="Article"
                      style={{ width: "100%" }}
                      src={bindPicturePath(
                        "custom_sections/" + article.id,
                        picture
                      )}
                    />
                  </Zoom>
                </Grid>
              ))}
            </Grid>
          )}
          {Boolean(article.link) && (
            <div style={{ padding: "2rem 0" }}>
              Source:{" "}
              <a
                href={article.link}
                className="clickable active"
                target="_blank"
                rel="noopener noreferrer"
              >
                {article.link}
              </a>
            </div>
          )}
        </PageContainer>
      )}
    </>
  );
}
