import Currency from "models/Currency";
import { getDefaultLanguage } from "models/Language";
import { atom } from "recoil";

const LANGUAGE_STORAGE_KEY = "language";
const CURRENCY_STORAGE_KEY = "currency";
const CART_STORAGE_KEY = "cart";

const DEFAULT_LANGUAGE = getDefaultLanguage();
const DEFAULT_CURRRENY = Currency.EUR;

function doResetStorage(key, newValue) {
  return (
    (key === LANGUAGE_STORAGE_KEY && newValue === DEFAULT_LANGUAGE) ||
    (key === CURRENCY_STORAGE_KEY && newValue === DEFAULT_CURRRENY) ||
    (key === CART_STORAGE_KEY && newValue === [])
  );
}

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (doResetStorage()) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const userState = atom({
  key: "userState",
  default: undefined,
});

export const languageState = atom({
  key: "languageState",
  default: DEFAULT_LANGUAGE,
  effects_UNSTABLE: [localStorageEffect(LANGUAGE_STORAGE_KEY)],
});

export const sessionTokenState = atom({
  key: "sessionTokenState",
  default: "",
});

export const cartState = atom({
  key: "cartState",
  default: [],
  effects_UNSTABLE: [localStorageEffect(CART_STORAGE_KEY)],
});
