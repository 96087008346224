import { Alert, Snackbar } from "@mui/material"
import React, { useState } from "react"

export function useToaster() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const openToaster = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const closeToaster = () => {
    setOpen(false);
    setMessage("");
  };

  return [open, message, openToaster, closeToaster];
}

export default function Toaster({open, handleClose, severity, children}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {children}
      </Alert>
    </Snackbar>
  );
}