import AddButton from "component/commons/buttons/AddButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";
import { INSERT_ID } from "utils/constants";
import AdminTable from "../commons/AdminTable";
import useBackHistory from "hooks/useBackHistory";
import Toaster, { useToaster } from "component/commons/Toaster";
import CustomSection from "models/CustomSection";
import { Tab, Tabs } from "@mui/material";

const TABLE_HEADERS = ["ID", "Name"];
const CUSTOM_SECTION_KEYS = Object.keys(CustomSection);

export default function CustomSectionTable() {
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const sessionToken = useRecoilValue(sessionTokenState);

  const [selectedCustomSection, setSelectedCustomSection] = useState(0);
  const [customSectionTableData, setCustomSectionTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  useEffect(() => {
    getCustomSections();
  }, []);

  useEffect(() => {
    setFilteredTableData(
      customSectionTableData.filter(
        (data) => data.type === CUSTOM_SECTION_KEYS[selectedCustomSection]
      )
    );
  }, [customSectionTableData, selectedCustomSection]);

  const getCustomSections = () => {
    API.get("/custom-section")
      .then(function (response) {
        let tableData = [];
        response.data.forEach((customSection) =>
          tableData.push({
            id: customSection.id,
            type: customSection.type,
            cells: [customSection.id, customSection.name],
          })
        );
        setCustomSectionTableData(tableData);
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const editCustomSection = (customSectionId) => {
    navigate(encodeURI(customSectionId), { ...backHistory });
  };

  const redirectCustomSection = (customSectionId) => {
    return `/${CustomSection[CUSTOM_SECTION_KEYS[selectedCustomSection]].endpoint}/${customSectionId}`
  }

  const deleteCustomSection = (customSectionId) => {
    API({
      method: "DELETE",
      url: `/custom-section/${customSectionId}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function () {
        setCustomSectionTableData(
          customSectionTableData.filter((customSection) => customSection.id !== customSectionId)
        );
        setFilteredTableData(
          filteredTableData.filter((customSection) => customSection.id !== customSectionId)
        );
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const isAddButtonEnabled = () => {
    return [CustomSection.PRESS.key, CustomSection.HISTO.key, CustomSection.PERSO.key].includes(
      CUSTOM_SECTION_KEYS[selectedCustomSection]
    );
  };

  return (
    <>
      <Tabs
        sx={{ marginBottom: "1rem" }}
        variant="scrollable"
        scrollButtons="auto"
        value={selectedCustomSection}
        onChange={(e, value) => setSelectedCustomSection(value)}
      >
        {CUSTOM_SECTION_KEYS.map((customSectionKey) => (
          <Tab key={customSectionKey} label={CustomSection[customSectionKey].label} />
        ))}
      </Tabs>
      <AdminTable
        name="custom section"
        headers={TABLE_HEADERS}
        rows={filteredTableData}
        redirectAction={redirectCustomSection}
        editAction={editCustomSection}
        deleteAction={deleteCustomSection}
      />
      {isAddButtonEnabled() && <AddButton onClick={() => editCustomSection(INSERT_ID)} />}
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}
