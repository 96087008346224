import React from "react";
import { Container, styled } from "@mui/material";
import SearchBox from "component/commons/inputs/SearchBox";
import PipeBreadcrumbs from "component/commons/PipeBreadcrumbs";
import ProductFilters from "component/creations/ProductFilters";
import useTranslate from "hooks/useTranslate";
import { useLocation, useNavigate } from "react-router-dom";
import creationsTexts from "translation/texts/creations-texts";
import useQuery from "hooks/useQuery";

const OTHER_CATEGORY = "Other";

const MainContainer = styled(Container)(
  () => `
  padding-top: 3rem;
  padding-bottom: 3rem;
`
);

const CategoryListWrapper = styled("div")(
  () => `
  width: 100%;
  text-align: center;
`
);

export default function CreationFilters({ categories, collections, onSearch }) {
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();
  const query = useQuery();

  const isCategoryActive = (categoryName) => {
    return (
      query.get("category") === categoryName ||
      (query.get("category") === null && categoryName === "")
    );
  };

  const updateFilters = (filter, value) => {
    let params = new URLSearchParams(query);
    if (params.get(filter) === value.toString()) {
      params.delete(filter);
    } else {
      params.set(filter, value);
    }
    navigate({ pathname: location.pathname, search: params.toString() });
  };

  return (
    <MainContainer maxWidth="lg">
      <CategoryListWrapper>
        <PipeBreadcrumbs
          elements={getCategoryFilterList(categories)}
          isActive={isCategoryActive}
          callback={(category_name) => updateFilters("category", category_name)}
        />
      </CategoryListWrapper>

      <div style={{ marginTop: "2rem" }}>
        <div style={{ float: "left" }}>
          <ProductFilters onFilterUpdate={updateFilters} collections={collections} />
        </div>
        <div style={{ float: "right" }}>
          <SearchBox text={translate(creationsTexts.SEARCH)} onSubmit={onSearch} />
        </div>
      </div>
    </MainContainer>
  );
}

const getCategoryFilterList = (categories) => {
  let categoryFilterList = [{ id: "", label: "All" }];
  categories.forEach((category) =>
    categoryFilterList.push({ id: category.name, label: category.name })
  );
  categoryFilterList.push({ id: OTHER_CATEGORY, label: OTHER_CATEGORY });
  return categoryFilterList;
};

export function filters(products, category, collection, gender, searchText) {
  return products.filter((product) => {
    if (
      category &&
      ((category !== OTHER_CATEGORY && category !== product.category_name) ||
        (category === OTHER_CATEGORY && product.category_name))
    ) {
      return false;
    } else if (collection && product.collect_id !== parseInt(collection)) {
      return false;
    } else if (gender && product.gender && product.gender !== gender) {
      return false;
    } else if (!isProductSearched(product, searchText)) {
      return false;
    } else {
      return true;
    }
  });
}

function equalsIgnoreCase(str1, str2) {
  return (
    str1 &&
    str1.localeCompare(str2, undefined, {
      usage: "search",
      sensitivity: "base",
      ignorePunctuation: true,
    }) === 0
  );
}

function isProductSearched(product, searchText) {
  if (searchText) {
    const isNameSearch =
      product.name &&
      product.name
        .split(" ")
        .map((namePart) => equalsIgnoreCase(namePart, searchText))
        .reduce((prev, next) => prev || next);
    const isTagSearched =
      product.tags &&
      product.tags
        .split(";")
        .map((tag) => equalsIgnoreCase(tag, searchText))
        .reduce((prev, next) => prev || next);

    return (
      isNameSearch ||
      isTagSearched ||
      equalsIgnoreCase(product.name, searchText) ||
      equalsIgnoreCase(product.category_name, searchText) ||
      equalsIgnoreCase(product.collect_name, searchText)
    );
  } else {
    return true;
  }
}
