import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fab,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { isPictureLink } from "utils/tools";
import { Link } from "react-router-dom";
import Miniature from "component/commons/images/Miniature";
import useTranslate from "hooks/useTranslate";
import ShowMoreButton from "component/commons/buttons/ShowMoreButton";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "80vh",
  "& th": {
    backgroundColor: theme.palette.background.primary,
  },
  "& button": {
    zIndex: 1,
  },
}));

const StyledFab = styled(Fab)(() => ({
  margin: "0 0.5rem",
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.text.dark,
  textTransform: "initial",
  textAlign: "left",
}));

export default function AdminTable({
  name,
  headers,
  rows,
  redirectAction,
  editAction,
  deleteAction,
  loadMoreProperties,
}) {
  const [openedModal, setOpenedModal] = useState(false);
  const [modalItemId, setModalItemId] = useState(null);

  const openModal = (id) => {
    setModalItemId(id);
    setOpenedModal(true);
  };

  const closeModal = () => {
    setOpenedModal(false);
    setModalItemId(null);
  };

  const hasAction = () => {
    return redirectAction || editAction || deleteAction;
  };

  return (
    <>
      {/* Admin table content */}
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
              {hasAction() && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow hover key={row.id}>
                {row.cells.map((cell, index) => (
                  <TableCell key={index}>
                    {isPictureLink(cell) ? <Miniature src={cell} alt={cell} /> : cell}
                  </TableCell>
                ))}
                {hasAction() && (
                  <TableCell>
                    {redirectAction && (
                      <Link to={redirectAction(row.id)} target="_blank" rel="noopener noreferrer">
                        <StyledFab color="info" size="small">
                          <FontAwesomeIcon icon={faEye} />
                        </StyledFab>
                      </Link>
                    )}
                    {editAction && (
                      <StyledFab color="info" size="small" onClick={() => editAction(row.id)}>
                        <FontAwesomeIcon icon={faPen} />
                      </StyledFab>
                    )}
                    {deleteAction && (
                      <StyledFab color="error" size="small" onClick={() => openModal(row.id)}>
                        <FontAwesomeIcon icon={faTimes} />
                      </StyledFab>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}

            {/* Show more */}
            {Boolean(loadMoreProperties) && (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <ShowMoreButton
                    doneFetching={loadMoreProperties.doneFetching}
                    isLoading={loadMoreProperties.isLoading}
                    hasResults={loadMoreProperties.hasResults}
                    callback={loadMoreProperties.callback}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Validation dialog */}
      <Dialog open={openedModal} onClose={closeModal}>
        <StyledDialogTitle>Validate your action</StyledDialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {name} with{" "}
            <b>
              {"{"}ID : {modalItemId}
              {"}"}
            </b>{" "}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            onClick={() => {
              deleteAction(modalItemId);
              closeModal();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
