import React from "react";
import borderBackground from "media/border-background.png";
import CustomSection from "models/CustomSection";
import { bindPicturePath } from "utils/tools";
import PreviewItem from "./PreviewItem";
import PromotedItemsUtils, { PROMTED_ITEM_TYPES } from "./PromotedItemsUtils";
import CoverImage from "component/commons/images/CoverImage";

export default function PromotedItems({ items }) {
  const computeItems = () => {
    let reverse = false;
    let domItems = [];

    items.forEach((item) => {
      let type = PromotedItemsUtils.getType(item);
      if (type === PROMTED_ITEM_TYPES.COLLECT) {
        domItems.push(<Collect key={item.id} data={item} reverse={reverse} />);
        reverse = !reverse;
      } else if (type === PROMTED_ITEM_TYPES.CUSTOM_SECTION) {
        domItems.push(
          <CustomSectionItem key={item.id} data={item} reverse={reverse} />
        );
        reverse = !reverse;
      } else if (type === PROMTED_ITEM_TYPES.PICTURE) {
        domItems.push(
          <CoverImage
            key={item.id}
            src={PromotedItemsUtils.getPictureFullLink(item.picture)}
          />
        );
      }
    });
    return domItems;
  };

  return <div>{computeItems()}</div>;
}

function Collect({ data, reverse }) {
  return (
    <PreviewItem
      src={
        data.collect_cover
          ? bindPicturePath(`collects/${data.collect_id}`, data.collect_cover)
          : borderBackground
      }
      subtitle="Collection"
      reverse={reverse}
      title={data.collect_name}
      body={{
        description: data.collect_desc,
        description_fr: data.collect_desc_fr,
      }}
      discoveryPath={`/collection/${data.collect_id}`}
    />
  );
}

function CustomSectionItem({ data, reverse }) {
  return (
    <PreviewItem
      src={
        data.custom_section_cover
          ? bindPicturePath("custom_sections", data.custom_section_cover)
          : borderBackground
      }
      reverse={reverse}
      title={data.custom_section_name}
      body={{
        description: data.custom_section_desc,
        description_fr: data.custom_section_desc_fr,
      }}
      discoveryPath={`/${CustomSection[data.custom_section_type].endpoint}/${
        data.custom_section_id
      }`}
    />
  );
}
