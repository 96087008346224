import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Box, Popover, styled } from "@mui/material";
import "style/override-popover.scss";

const PopoverBox = styled(Box)(
  ({ theme }) => `
  padding: 10px;
  background-color: ${theme.palette.background.light};
  color: ${theme.palette.text.primary};
`
);

export default function HeaderPopover({ icon, children }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-controls="popover"
        aria-haspopup="true"
        onClick={handleOpen}
        size="large"
      >
        {icon}
      </IconButton>
      <Popover
        id="popover"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <PopoverBox>
          <FontAwesomeIcon icon={faCaretUp} style={{ float: "right" }} />
          {children}
        </PopoverBox>
      </Popover>
    </div>
  );
}
