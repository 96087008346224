import React from "react";
import { Box, IconButton, MenuItem, styled, Tooltip } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { PermIdentity, ShoppingBasketOutlined } from "@mui/icons-material";
import MoreIcon from "@mui/icons-material/MoreVert";
import ActionBadge from "component/commons/ActionBadge";
import HeaderPopover from "component/headers/HeaderPopover";
import Language from "models/Language";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { languageState, userState } from "store/atoms";
import { cartItemsCountState, userSignedInState } from "store/selectors";
import headerTexts from "translation/texts/header-texts";
import SimpleButton from "component/commons/buttons/SimpleButton";
import { useAuth0 } from "@auth0/auth0-react";
import useLogin from "hooks/useLogin";
import colors from "style/_colors.scss";
import { APP_BAR_HEIGHT } from "utils/constants";
import useTranslate from "hooks/useTranslate";

const COMING_SOON = {
  EN: "Coming soon",
  FR: "Bientôt disponible",
};

const languages = Object.entries(Language).map((language) => language[1]);

const DesktopBox = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  height: ${APP_BAR_HEIGHT}px;
  ${theme.breakpoints.up("sm")} {
    display: flex;
  }
  ${theme.breakpoints.down("sm")} {
    display: none;
  }`
);

const MobileBox = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  height: ${APP_BAR_HEIGHT}px;
  ${theme.breakpoints.up("sm")} {
    display: none;
  }
  ${theme.breakpoints.down("sm")} {
    display: flex;
  }`
);

export const AppbarIcons = ({ handleProfileClick, handleBasketClick }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const isLoadingLogin = useLogin();

  const userSignedIn = useRecoilValue(userSignedInState);
  const basketCount = useRecoilValue(cartItemsCountState);
  const [language, setLanguage] = useRecoilState(languageState);

  return (
    <Box sx={{ position: "absolute", right: 30 }}>
      {/* Desktop Section */}
      <DesktopBox>
        <IconButton color="inherit" onClick={handleProfileClick} size="large">
          <PermIdentity />
        </IconButton>
        <HeaderPopover
          icon={
            <span style={{ fontFamily: "Arial", fontSize: "1.3rem" }}>
              {language.key}
            </span>
          }
        >
          {languages.map((lang) => (
            <MenuItem
              key={lang.key}
              selected={language.key === lang.key}
              onClick={() => setLanguage(lang)}
              sx={{ color: "text.primary" }}
            >
              <ListItemText primary={lang.key} />
            </MenuItem>
          ))}
        </HeaderPopover>
        <IconButton color="inherit" onClick={handleBasketClick} size="large">
          <ActionBadge badgeContent={basketCount} color="primary" max={9}>
            <ShoppingBasketOutlined />
          </ActionBadge>
        </IconButton>
      </DesktopBox>

      {/* Mobile Section */}
      <MobileBox>
        <HeaderPopover icon={<MoreIcon />}>
          {isLoadingLogin && (
            <MenuItem>{translate(headerTexts.LOADING)} &hellip;</MenuItem>
          )}
          {!isLoadingLogin && !userSignedIn && (
            <MenuItem>
              <LoginButton />
            </MenuItem>
          )}
          {!isLoadingLogin && userSignedIn && (
            <MenuItem>
              <Tooltip
                title={translate(COMING_SOON)}
                enterTouchDelay={0}
                enterDelay={0}
                placement="top"
                arrow
              >
                <div
                  style={{ color: colors.secondaryFont, fontSize: "smaller" }}
                >
                  {translate(headerTexts.MY_ACCOUNT)}
                </div>
              </Tooltip>
            </MenuItem>
          )}
          {!isLoadingLogin && (
            <MenuItem>
              <SimpleButton onClick={() => navigate("/orders")}>
                {translate(headerTexts.MY_ORDERS)}
              </SimpleButton>
            </MenuItem>
          )}
          {!isLoadingLogin && userSignedIn && (
            <MenuItem>
              <LogoutButton />
            </MenuItem>
          )}
        </HeaderPopover>
      </MobileBox>
    </Box>
  );
};

function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  const translate = useTranslate();

  return (
    <SimpleButton
      onClick={() => loginWithRedirect({ redirectUri: window.location.origin })}
    >
      {translate(headerTexts.SIGN_IN)}
    </SimpleButton>
  );
}

function LogoutButton() {
  const { logout } = useAuth0();
  const translate = useTranslate();
  const setUser = useSetRecoilState(userState);

  const handleLogout = () => {
    setUser(undefined);
    logout({ returnTo: window.location.origin });
  };

  return (
    <SimpleButton onClick={handleLogout}>
      {translate(headerTexts.SIGN_OUT)}
    </SimpleButton>
  );
}
