import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fab, styled } from "@mui/material";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const StyledFab = styled(Fab)(() => ({
    position: "fixed",
    bottom: 20,
    right: 20,
}))

export default function AddButton({ onClick }) {
  return (
    <StyledFab color="success" onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} />
    </StyledFab>
  );
}
