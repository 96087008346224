import useOnScreen from "hooks/useOnScreen";
import { useEffect, useRef } from "react";

export default function ScreenVisibilityDetector({ onVisible }) {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);

  useEffect(() => {
    if (isOnScreen) {
      onVisible();
    }
  }, [isOnScreen]);

  return <div ref={elementRef} />;
}
