import React from "react";
import Page from "component/commons/page/Page";
import headerCover from "media/contactpage_header.png";
import contactTexts from "translation/texts/contact-texts";
import CoverImage from "component/commons/images/CoverImage";
import PageContainer from "component/commons/page/PageContainer";
import PageTitle from "component/commons/page/PageTitle";
import Description from "component/commons/Description";
import useTranslate from "hooks/useTranslate";

export default function Contact() {
  const translate = useTranslate();

  return (
    <Page title="Contact" endpoint="contact" variant="lightBackground">
      <CoverImage src={headerCover} />
      <PageContainer maxWidth="md" sx={{ paddingBottom: "3rem" }}>
        <PageTitle>{translate(contactTexts.CONTACT_US)}</PageTitle>
        <Description text={translate(contactTexts.SUBTEXT)} />
      </PageContainer>
    </Page>
  );
}
