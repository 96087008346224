import React from "react";
import Description from "component/commons/Description";
import Page from "component/commons/page/Page";
import { useEffect, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import API from "services/API";
import PageContainer from "component/commons/page/PageContainer";
import PageTitle from "component/commons/page/PageTitle";
import useTranslateLabel from "hooks/useTranslateLabel";
import useTranslateDescription from "hooks/useTranslateDescription";

export default function FooterPage({ type }) {
  const translateLabel = useTranslateLabel();
  const translateDescription = useTranslateDescription();

  const [section, setSection] = useState(null);

  useEffect(() => {
    getSection();
  }, [type]);

  const getSection = () => {
    API.get("/custom-section", {
      params: {
        sectionType: type.key,
      },
    })
      .then(function (response) {
        setSection(response.data[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Page
      title={translateLabel(type)}
      endpoint={type.endpoint}
      variant="lightBackground"
    >
      <PageContainer maxWidth="lg">
        <PageTitle>{translateLabel(type)}</PageTitle>
        {Boolean(section) && (
          <Description text={translateDescription(section)} />
        )}
        <br />
      </PageContainer>
    </Page>
  );
}
