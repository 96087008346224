import React from "react";
import { Grid, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { cartState } from "store/atoms";
import headerTexts from "translation/texts/header-texts";
import {
  bindPicturePath,
  displayPrice,
  explodePath2getName,
} from "utils/tools";
import Separator from "component/commons/Separator";
import ContainedImage from "component/commons/images/ContainedImage";
import useTranslate from "hooks/useTranslate";
import TextElement from "component/commons/typography/TextElement";
import TextSize from "component/commons/typography/TextSize";

export default function BasketItems() {
  const [cart, setCart] = useRecoilState(cartState);

  const handleRemoveItem = (e, index) => {
    e.stopPropagation();
    let cartTmp = cart.slice();
    cartTmp.splice(index, 1);
    setCart(cartTmp);
  };

  return (
    <div>
      {cart.map((item, index) => (
        <div key={index}>
          <BasketItem
            index={index}
            item={item}
            handleRemove={handleRemoveItem}
          />
          {index < cart.length - 1 && (
            <Separator
              variant="grey"
              fullWidth={true}
              thin={true}
              margin="1rem"
            />
          )}
        </div>
      ))}
    </div>
  );
}

function BasketItem({ index, item, handleRemove }) {
  const translate = useTranslate();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={4}>
        <ContainedImage
          src={bindPicturePath(
            "products",
            item.product.id,
            item.product.first_pic
          )}
        />
      </Grid>
      <Grid item xs={7}>
        <TextElement Tag="div" size={TextSize.SMALL} transform="uppercase" weigth="bold">
          {item.product.name}
        </TextElement>
        {Boolean(item.color) && (
          <TextElement Tag="div" size={TextSize.SMALL}>
            {translate(headerTexts.COLOR)} : {explodePath2getName(item.color)}
          </TextElement>
        )}
        {Boolean(item.size) && (
          <TextElement Tag="div" size={TextSize.SMALL}>
            {translate(headerTexts.SIZE)} : {item.size}
          </TextElement>
        )}
        <TextElement Tag="div" size={TextSize.SMALL}>
          {displayPrice(item.product.price)}
        </TextElement>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="inherit"
          edge="end"
          onClick={(e) => handleRemove(e, index)}
          size="large"
        >
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  );
}
