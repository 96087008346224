import React from "react";
import AdministrationPage from "component/pages/AdministrationPage";
import SecureComponent from "component/commons/router/SecureComponent";
import Contact from "component/pages/ContactPage";
import FooterPage from "component/pages/FooterPage";
import OrderPage from "component/pages/OrderPage";
import CollectionPage, { SingleCollection } from "component/pages/CollectionPage";
import CreationPage from "component/pages/CreationPage";
import HistoryPage from "component/pages/HistoryPage";
import Home from "component/pages/HomePage";
import PressPage from "component/pages/PressPage";
import CustomSection from "models/CustomSection";
import Roles from "models/Roles";
import { Outlet, Route, Routes } from "react-router-dom";
import AdministrationMenu from "component/administration/commons/AdministrationMenu";
import administrationRoutes from "./administration-routes";
import CraftmanshipPage from "component/pages/CraftmanshipPage";
import NotFoundPage from "component/pages/NotFoundPage";
import ProductsOverview from "component/creations/ProductsOverview";
import ProductOverview from "component/creations/ProductOverview";
import PressOverview from "component/press/PressOverview";
import PressArticle from "component/press/PressArticle";
import BackButton from "component/commons/buttons/BackButton";

export default function AppRoutes() {
  return (
    <Routes>
      <Route index element={<Home />} />

      <Route path="creations" element={<CreationPage />}>
        <Route index element={<ProductsOverview />} />
        <Route path=":productId" element={<ProductOverview />} />
      </Route>

      <Route path="craftmanship" element={<CraftmanshipPage />} />
      <Route path="history" element={<HistoryPage />} />

      <Route path="press" element={<PressPage />}>
        <Route index element={<PressOverview />} />
        <Route path=":sectionId" element={<PressArticle />} />
      </Route>

      <Route path="collection" element={<CollectionPage />}>
        <Route path=":collectId" element={<SingleCollection />} />
      </Route>

      <Route path="orders" element={<OrderPage />} />

      {/* Footer */}
      <Route
        path={CustomSection.ABOUT.endpoint}
        element={<FooterPage type={CustomSection.ABOUT} />}
      />
      <Route path="contact" element={<Contact />} />
      <Route
        path={CustomSection.SUSTAIN.endpoint}
        element={<FooterPage type={CustomSection.SUSTAIN} />}
      />
      <Route
        path={CustomSection.PRIVACY.endpoint}
        element={<FooterPage type={CustomSection.PRIVACY} />}
      />
      <Route
        path={CustomSection.TERMS.endpoint}
        element={<FooterPage type={CustomSection.TERMS} />}
      />
      <Route
        path={CustomSection.CERTIFS.endpoint}
        element={<FooterPage type={CustomSection.CERTIFS} />}
      />
      <Route
        path={CustomSection.SHIPPING.endpoint}
        element={<FooterPage type={CustomSection.SHIPPING} />}
      />

      {/* Administration */}
      <Route
        path="administration"
        element={
          <SecureComponent authorization={[Roles.ADMIN, Roles.OWNER]}>
            <AdministrationPage />
          </SecureComponent>
        }
      >
        <Route index element={<AdministrationMenu elements={administrationRoutes} />} />
        {administrationRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <SecureComponent authorization={route.authorization}>
                <BackButton />
                <Outlet />
              </SecureComponent>
            }
          >
            {Boolean(route.children) &&
              route.children.map((childRoute) => (
                <Route
                  key={childRoute.path}
                  path={childRoute.path}
                  element={
                    <SecureComponent authorization={childRoute.authorization}>
                      {childRoute.element}
                    </SecureComponent>
                  }
                />
              ))}
          </Route>
        ))}
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
