import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import OrderStatus from "models/OrderStatus";
import React from "react";
import ordersTexts from "translation/texts/orders-texts";
import { displayDate, displayPrice } from "utils/tools";
import useTranslate from "hooks/useTranslate";
import useTranslateLabel from "hooks/useTranslateLabel";
import { useRecoilValue } from "recoil";
import { languageState } from "store/atoms";

const columns = [
  ordersTexts.ORDER_PLACED_ON,
  ordersTexts.TOTAL,
  ordersTexts.LAST_STATUS,
  ordersTexts.ORDER_NO,
];

export default function OrderTable({ order }) {
  const language = useRecoilValue(languageState);
  const translate = useTranslate();
  const translateLabel = useTranslateLabel();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{translate(column)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={order.id}>
            <TableCell>{displayDate(language, order.creation_ts)}</TableCell>
            <TableCell>
              {displayPrice(order.currency, order.total_price)}
            </TableCell>
            <TableCell>{`${translateLabel(
              OrderStatus[order.status]
            )} ${displayDate(language, order.last_update)}`}</TableCell>
            <TableCell>{order.id}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
