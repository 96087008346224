import React from "react";
import { Box, Grid } from "@mui/material";
import ActionButton from "component/commons/buttons/ActionButton";
import Description from "component/commons/Description";
import Separator from "component/commons/Separator";
import { useNavigate } from "react-router-dom";
import homeTexts from "translation/texts/home-texts";
import BackgroundImage from "component/commons/images/BackgroundImage";
import useTranslateDescription from "hooks/useTranslateDescription";
import useTranslate from "hooks/useTranslate";

const styles = {
  gridItem: { textAlign: "center", padding: "2em" },
};

export default function PreviewItem({
  src,
  subtitle,
  reverse,
  title,
  body,
  discoveryPath,
}) {
  let navigate = useNavigate();
  const translate = useTranslate();
  const translateDescription = useTranslateDescription();

  const handleDiscoverClick = () => {
    if (discoveryPath) {
      navigate(discoveryPath, { enableGoBack: true });
    }
  };

  return (
    <Grid
      container
      direction={reverse ? "row-reverse" : "row"}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={6} sx={styles.gridItem}>
        <BackgroundImage style={{ backgroundImage: `url("${src}")` }} />
      </Grid>
      <Grid item xs={12} sm={6} sx={styles.gridItem}>
        {subtitle && <h3>{subtitle}</h3>}
        <h1>{title}</h1>
        <Separator />
        <Box sx={{ margin: "1.5em 0" }}>
          <Description text={translateDescription(body)} maxNbLines={10} />
        </Box>
        <ActionButton onClick={handleDiscoverClick} style={{width: "50%"}}>
          {translate(homeTexts.DISCOVER)}
        </ActionButton>
      </Grid>
    </Grid>
  );
}
