import { Container } from "@mui/material";
import Page from "component/commons/page/Page";
import React from "react";
import { Outlet } from "react-router-dom";

export default function AdministrationPage() {
  return (
    <Page title="Administration" endpoint="administration">
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </Page>
  );
}
