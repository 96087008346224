import React from "react";
import Image from "mui-image";

const imageStyle = { maxHeight: "80vh", maxWidth: "100%" };

const ErrorImage = ({fit, style}) => (
  <img
    src="/logo-made_in_paris.png"
    style={{ ...imageStyle, objectFit: fit || "contain", ...style }}
  />
);

export default function ContainedImage({ src, fit, style }) {
  return (
    <Image
      src={src}
      height="100%"
      width="100%"
      fit={fit || "contain"}
      duration={2000}
      easing="cubic-bezier(0.7, 0, 0.6, 1)"
      showLoading={true}
      errorIcon={<ErrorImage fit={fit} style={style} />}
      bgColor="transparent"
      style={{ ...imageStyle, ...style }}
    />
  );
}
