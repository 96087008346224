import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CircularBackdrop from "component/commons/CircularBackdrop";
import ContainedImage from "component/commons/images/ContainedImage";
import UploadFilesDetails from "component/commons/UploadFilesDetails";
import PromotedItemsUtils, { PROMTED_ITEM_TYPES } from "component/home/PromotedItemsUtils";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";
import {
  greenBackground,
  noDecoration,
  redBackground,
  SelectInputProps,
  whiteColor,
} from "style/style";
import { INSERT_ID } from "utils/constants";
import AdminFormBuilder from "../commons/form/AdminFormBuilder";
import AdminFormType from "../commons/form/AdminFormType";
import Toaster, { useToaster } from "component/commons/Toaster";
import useBackendService from "hooks/useBackendService";
import LoadingPage from "component/pages/LoadingPage";
import AdminEditForm from "../commons/form/AdminEditForm";
import { getUpdatedCoverPath } from "../commons/utilities";
import picturesUpload from "../commons/pictures-upload";
import useBackHistory from "hooks/useBackHistory";

export default function PromotedItemEdit() {
  let { itemId } = useParams();
  let navigate = useNavigate();
  const backHistory = useBackHistory();

  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  const [itemPicture, setItemPicture] = useState("");
  const [promotedItemFormData, setPromotedItemFormData] = useState(buildForm({}));

  useEffect(() => {
    if (itemId && itemId !== INSERT_ID) {
      getItem(itemId);
    }
  }, [itemId]);

  const getItem = () => {
    apiCall({ method: "GET", url: `/home/${itemId}` })
      .then(function (response) {
        const item = response.data;
        setItemPicture(item.picture);
        setPromotedItemFormData(buildForm(item));
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const updateItem = (formValues) => {
    let collectId = null;
    let customSectionId = null;
    if (formValues.type === PROMTED_ITEM_TYPES.COLLECT.id) {
      collectId = formValues.collectId;
    } else if (formValues.type === PROMTED_ITEM_TYPES.CUSTOM_SECTION.id) {
      customSectionId = formValues.customSectionId;
    }

    const { cover_path, previous_picture } = getPicturesUpdates(formValues);

    apiCall({
      method: itemId === INSERT_ID ? "POST" : "PUT",
      url: "/home",
      data: {
        id: itemId,
        collect_id: collectId,
        custom_section_id: customSectionId,
        picture: cover_path,
        previous_picture: previous_picture,
        position: formValues.position,
      },
    })
      .then(function (response) {
        const { insertId } = response.data;

        if (formValues.type === PROMTED_ITEM_TYPES.PICTURE.id && formValues.cover.uploads.length) {
          uploadPicture(formValues);
        }

        if (insertId) {
          navigate("../" + insertId, { replace: true, ...backHistory });
        } else {
          getItem(itemId);
        }
      })
      .catch(function (error) {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const getPicturesUpdates = (formValues) => {
    let cover = [];
    if (formValues.type === PROMTED_ITEM_TYPES.PICTURE.id) {
      cover = formValues.cover.uploads;
    }
    return getUpdatedCoverPath(cover, itemPicture);
  };

  const uploadPicture = (formValues) => {
    picturesUpload(apiCall, formValues.cover.uploads, "promotedItem_cover", "home").catch(function (
      error
    ) {
      openToaster(error.toString());
      console.error(error);
    });
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <AdminEditForm formData={promotedItemFormData} onSubmit={updateItem} />
      )}
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}

function buildForm(promotedItem) {
  let form = [];

  form.push(
    new AdminFormBuilder(AdminFormType.SELECT_MENU)
      .withKey("type")
      .withLabel("Type")
      .withInitialValue(PromotedItemsUtils.getType(promotedItem).id)
      .withDefaultValue(PROMTED_ITEM_TYPES.COLLECT.id)
      .addItem(PROMTED_ITEM_TYPES.COLLECT.id, PROMTED_ITEM_TYPES.COLLECT.label)
      .addItem(PROMTED_ITEM_TYPES.CUSTOM_SECTION.id, PROMTED_ITEM_TYPES.CUSTOM_SECTION.label)
      .addItem(PROMTED_ITEM_TYPES.PICTURE.id, PROMTED_ITEM_TYPES.PICTURE.label)
      .makeMandatory()
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("position")
      .withLabel("Position (greater number will appear first)")
      .withInitialValue(promotedItem.position)
      .withDefaultValue(0)
      .makeNumber()
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("collectId")
      .withLabel(`Collection ID (used only if type is "Collection")`)
      .withInitialValue(promotedItem.collect_id)
      .withDefaultValue("")
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.TEXT_INPUT)
      .withKey("customSectionId")
      .withLabel(`Custom Section ID (used only if type is "Custom Section")`)
      .withInitialValue(promotedItem.custom_section_id)
      .withDefaultValue("")
      .build()
  );

  form.push(
    new AdminFormBuilder(AdminFormType.PICTURE_UPLOAD)
      .withKey("cover")
      .withLabel(`Cover Picture (used only if type is "Picture")`)
      .withFolder(PromotedItemsUtils.getPictureFullLink(""))
      .withInitialValue({ pictures: [PromotedItemsUtils.getPictureFullLink(promotedItem.picture)] })
      .withDefaultValue({})
      .build()
  );

  return form;
}
