import CollapsibleTable from "component/commons/CollapsibleTable";
import Avatar from "component/commons/images/Avatar";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";

const HEADERS = ["", "ID", "Email", "First name", "Last name", "Instagram"];
const ADDITIONAL_HEADERS = ["External ID", "WhatsApp", "Subscribed", "Role"];

export default function UserTable() {
  const [users, setUsers] = useState([]);
  const sessionToken = useRecoilValue(sessionTokenState);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    API({
      method: "GET",
      url: "/user/admin",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function (response) {
        setUsers(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getRows = () => {
    return users.map((user) => ({
      key: user.id,
      columns: [
        <Avatar path={user.picture} />,
        user.id,
        user.email,
        user.firstname,
        user.lastname,
        user.instagram,
      ],
      additionalData: {
        name: "Details",
        headers: ADDITIONAL_HEADERS,
        rows: [
          [
            user.external_id,
            user.whatsapp,
            user.subscribed ? "yes" : "no",
            user.role,
          ],
        ],
      },
    }));
  };

  return (
    <React.Fragment>
      <CollapsibleTable headers={HEADERS} rows={getRows()} />
    </React.Fragment>
  );
}
