export default {
  ACCOUNT_CREATION: {
    EN: "Create an account to benefit from our exclusive services and to keep up to date with our latest publications.",
    FR: "Créez un compte pour bénéficier de nos services exclusif et rester informé de nos dernières publications.",
  },
  ADMIN_SECTION: {
    EN: "Admin Section",
    FR: "Section Administrateur",
  },
  ADMINISTRATION: {
    EN: "Administration",
    FR: "Administration",
  },
  ANALYTICS: {
    EN: "Analytics",
    FR: "Analytiques",
  },
  BASKET: {
    EN: "Basket",
    FR: "Panier",
  },
  CONNEXION: {
    EN: "Connexion",
    FR: "Connexion",
  },
  COLLECTIONS: {
    EN: "Collections",
    FR: "Collections",
  },
  COLOR: {
    EN: "Color",
    FR: "Couleur",
  },
  CONTACT: {
    EN: "Contact",
    FR: "Contact",
  },
  CREATIONS: {
    EN: "Creations",
    FR: "Créations",
  },
  CRAFTMANSHIP: {
    EN: "Craftmanship",
    FR: "Savoir faire",
  },
  HISTORY: {
    EN: "History",
    FR: "Histoire",
  },
  HOME: {
    EN: "Home",
    FR: "Accueil",
  },
  LOADING: {
    EN: "Loading",
    FR: "Chargement",
  },
  MY_ACCOUNT: {
    EN: "My account",
    FR: "Mon compte",
  },
  MY_ORDERS: {
    EN: "My orders",
    FR: "Mes commandes",
  },
  NEW_CLIENT: {
    EN: "New client",
    FR: "Nouveau client",
  },
  NO_ITEMS_IN_BASKET: {
    EN: 'Your basket in empty. You can go to "Creations" to add some items',
    FR: 'Votre panier est vide. Vous pouvez aller dans "Créations" pour ajouter des produits',
  },
  PRESS: {
    EN: "Press",
    FR: "Presse",
  },
  SEARCH_CONSOLE: {
    EN: "Search console",
    FR: "Search console",
  },
  SEE_SHOPPING_CART: {
    EN: "See shopping cart",
    FR: "Voir le panier",
  },
  SIGN_IN: {
    EN: "Sign in",
    FR: "Connexion",
  },
  SIGN_OUT: {
    EN: "Sign out",
    FR: "Déconnexion",
  },
  SIZE: {
    EN: "Size",
    FR: "Taille",
  },
};
