export default function handleError(toaster, error, prefixMessage) {
  let message = prefixMessage ? prefixMessage : "";
  if (error.response?.data?.error) {
    message += error.response.data.error.message;
  } else {
    error.toString();
  }
  toaster(message);
  console.error(prefixMessage, error);
}
