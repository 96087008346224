import { Tooltip, styled } from "@mui/material";
import ContainedImage from "component/commons/images/ContainedImage";
import React from "react";
import { bindPicturePath, explodePath2getName } from "utils/tools";

const ColorContainer = styled("div")(({ theme, selected }) => ({
  borderRadius: 3,
  padding: 1,
  "& img": {
    borderRadius: 3,
  },
  "&:hover": {
    cursor: selected ? "initial" : "pointer",
  },
  boxShadow: selected
    ? `0px 0px 1px 1px ${theme.palette.action.active}`
    : undefined,
}));

export default function ColorPicture({
  colorPicture,
  productId,
  selected,
  onClick,
}) {
  return (
    <Tooltip
      title={explodePath2getName(colorPicture)}
      arrow
      placement="top"
      enterDelay={200}
      enterTouchDelay={0}
    >
      <ColorContainer selected={selected} onClick={onClick}>
        <ContainedImage
          src={bindPicturePath("products", productId, "colors", colorPicture)}
        />
      </ColorContainer>
    </Tooltip>
  );
}
