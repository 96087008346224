import Page from "component/commons/page/Page";
import cover from "media/Galbert 2022.mp4";
import React, { useEffect, useState } from "react";
import API from "services/API";
import headerTexts from "translation/texts/header-texts";
import MainCarousel from "../home/MainCarousel";
import PromotedItems from "../home/PromotedItems";
import CoverVideo from "component/commons/videos/CoverVideo";
import useTranslate from "hooks/useTranslate";

export default function HomePage() {
  const translate = useTranslate();

  const [promotedItems, setPromotedItems] = useState([]);
  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    getPromotedItems();
  }, []);

  const getPromotedItems = () => {
    API.get("/home")
      .then(function (response) {
        if (response.data) {
          setPictures(response.data.carousel);
          setPromotedItems(response.data.items);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <Page title={translate(headerTexts.HOME)} endpoint="">
      <MainCarousel sources={pictures} />

      <CoverVideo autoPlay loop muted playsInline>
        <source src={cover}></source>
      </CoverVideo>

      <PromotedItems items={promotedItems} />
    </Page>
  );
}
