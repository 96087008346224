import { styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TextElement from "../typography/TextElement";
import useBackHistory from "hooks/useBackHistory";

const StyledLink = styled(Link)(({theme}) => ({
  textDecorationLine: "none",
    textAlign: "center",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.action.active,
    },
}))

export default function IconTextLink({ to, icon, text }) {
  const backHistory = useBackHistory();
  return (
    <StyledLink to={to} state={{...backHistory.state}}>
      <TextElement Tag="h4">
        {icon}
        <br />
        {text}
      </TextElement>
    </StyledLink>
  );
}
