import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip, styled } from "@mui/material";
import ClassicButton from "component/commons/buttons/ClassicButton";
import Separator from "component/commons/Separator";
import useLogin from "hooks/useLogin";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "store/atoms";
import headerTexts from "translation/texts/header-texts";
import useTranslate from "hooks/useTranslate";
import TextElement from "component/commons/typography/TextElement";
import TextSize from "component/commons/typography/TextSize";
import RightDrawerContainer from "./RightDrawerContainer";

const COMING_SOON = {
  EN: "Coming soon",
  FR: "Bientôt disponible",
};

const MenuElement = styled("div")(() => ({
  width: "fit-content",
  opacity: 0.6
}))

export default function LoginMenu({ onClose, topMargin }) {
  const isLoadingLogin = useLogin();

  const user = useRecoilValue(userState);

  const getComponent = () => {
    if (isLoadingLogin) {
      return <Loading />;
    } else if (user) {
      return <Profile user={user} />;
    } else {
      return <SignIn />;
    }
  };

  return (
    <RightDrawerContainer
      paddingTop={topMargin}
      role="presentation"
      onClick={(e) => onClose(e)}
      onKeyDown={(e) => onClose(e)}
    >
      {getComponent()}
    </RightDrawerContainer>
  );
}

function SignIn() {
  const translate = useTranslate();

  return (
    <div>
      <TextElement Tag="h2">{translate(headerTexts.CONNEXION)}</TextElement>
      <TextElement Tag="div" size={TextSize.LARGE}>{translate(headerTexts.NEW_CLIENT)}</TextElement>
      <br />
      <div>{translate(headerTexts.ACCOUNT_CREATION)}</div>
      <br />
      <LoginButton />
    </div>
  );
}

function Loading() {
  const translate = useTranslate();
  return (
    <div>
      <h4>{translate(headerTexts.LOADING)} &hellip;</h4>
    </div>
  );
}

function Profile({ user }) {
  const translate = useTranslate();

  return (
    <div>
      <TextElement Tag="h2" style={{textTransform: "initial"}}>
        {user.firstname} {user.lastname}
      </TextElement>
      <br />
      <Tooltip
        title={translate(COMING_SOON)}
        enterTouchDelay={0}
        enterDelay={200}
        placement="top"
        arrow
      >
        <MenuElement>{translate(headerTexts.MY_ACCOUNT)}</MenuElement>
      </Tooltip>
      <Tooltip
        title={translate(COMING_SOON)}
        enterTouchDelay={0}
        enterDelay={200}
        placement="top"
        arrow
      >
        <MenuElement>{translate(headerTexts.MY_ORDERS)}</MenuElement>
      </Tooltip>
      <Separator color="gold" fullWidth={true} thin={true} margin="2rem" />
      <LogoutButton />
    </div>
  );
}

function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  const translate = useTranslate();

  return (
    <ClassicButton
      onClick={() => loginWithRedirect({ redirectUri: window.location.origin })}
    >
      {translate(headerTexts.SIGN_IN)}
    </ClassicButton>
  );
}

function LogoutButton() {
  const { logout } = useAuth0();
  const translate = useTranslate();
  const setUser = useSetRecoilState(userState);

  const handleLogout = () => {
    setUser(undefined);
    logout({ returnTo: window.location.origin });
  };

  return (
    <ClassicButton onClick={handleLogout}>
      {translate(headerTexts.SIGN_OUT)}
    </ClassicButton>
  );
}
