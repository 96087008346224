import { isFrench } from "models/Language";
import { useRecoilValue } from "recoil";
import { languageState } from "store/atoms";

function translateLabel(language, element) {
  if (isFrench(language) && Boolean(element.label_fr)) {
    return element.label_fr;
  } else {
    return element.label;
  }
}

export default function useTranslateLabel() {
  const language = useRecoilValue(languageState);
  return (element) => translateLabel(language, element);
}
