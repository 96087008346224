import { styled } from "@mui/material";

const RightDrawerContainer = styled("div")(({ paddingTop }) => ({
  marginTop: "1rem",
  paddingLeft: 50,
  paddingRight: 50,
  width: 300,
  paddingTop: paddingTop,
}));

export default RightDrawerContainer;