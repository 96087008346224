import React, { useState } from "react";

export default function usePageFetcher() {
  const [doneFetching, setDoneFetching] = useState(false);
  const [nextStart, setNextStart] = useState(0);
  const [data, setData] = useState([]);

  const addNextPage = (additionalData) => {
    if (additionalData.length) {
      let tmpData = data.concat(additionalData);
      setData(tmpData);
      setNextStart(tmpData.length);
    } else {
      setDoneFetching(true);
    }
  };

  const resetPages = (initialPage) => {
    setData(initialPage);
    setNextStart(initialPage.length);
    if (initialPage.length) {
      setDoneFetching(false);
    } else {
      setDoneFetching(true);
    }
  }

  return {
    data,
    addNextPage,
    resetPages,
    nextStart,
    doneFetching,
  };
}
