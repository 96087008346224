import { useAuth0 } from "@auth0/auth0-react";
import useAccessToken from "hooks/useAccessToken";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import API from "services/API";
import { sessionTokenState, userState } from "store/atoms";

export default function useLogin() {
  const {
    user: auth0User,
    isAuthenticated,
    isLoading: isLoadingAuth0,
  } = useAuth0();
  const { getAccessToken } = useAccessToken({ scope: "read:current_user" });
  const setUser = useSetRecoilState(userState);
  const setSessionToken = useSetRecoilState(sessionTokenState);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoadingAuth0 && isAuthenticated) {
      onSignIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAuth0, isAuthenticated]);

  const onSignIn = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessToken();
      API({
        method: "POST",
        url: "/login",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          userId: auth0User.sub,
        },
      })
        .then(function (response) {
          const { user: tgUser, sessionToken } = response.data;
          setUser(tgUser);
          setSessionToken(sessionToken);
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return isLoadingAuth0 || isLoading;
}
