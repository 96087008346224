import React from "react";
import TextLabel from "./TextLabel";
import ActionButton from "component/commons/buttons/ActionButton";
import { Close, PhotoCamera } from "@mui/icons-material";
import { Fab, Grid, styled } from "@mui/material";
import TextSize from "component/commons/typography/TextSize";
import ContainedImage from "component/commons/images/ContainedImage";
import { bindPicturePath } from "utils/tools";

const PictureList = styled("ul")(() => ({
  display: "flex",
  listStyle: "none",
  margin: 0,
  padding: 0,
}));

const PictureListElement = styled("li")(() => ({
  position: "relative",
  marginRight: "0.5rem",
  minWidth: 50,
}));

const CloseBtn = styled(Fab)(() => ({
  position: "absolute",
  bottom: -5,
  left: "50%",
  transform: "translateX(-50%)",
}));

export default function AdminPictureUpload({ data, value, setValue }) {
  const setPictures = (files) => {
    setValue(data.key, {
      ...value,
      uploads: files,
    });
  };

  return (
    <Grid item xs={12}>
      <TextLabel Tag="h4" size={TextSize.SMALL}>
        {data.label}
      </TextLabel>

      <div>
        <ActionButton component="label" startIcon={<PhotoCamera />}>
          Upload
          <input
            onChange={(e) => setPictures(e.target.files)}
            hidden
            accept="image/*"
            multiple={data.multiple}
            type="file"
          />
        </ActionButton>
        <ul>
          {Boolean(value.uploads) &&
            Array.from(value.uploads).map((file) => <li key={file.name}>{file.name}</li>)}
        </ul>
      </div>

      <PictureList>
        {value.pictures?.map((src) => (
          <PictureListElement key={src}>
            <ContainedImage src={bindPicturePath(data.folder, src)} />
            {Boolean(data.onDelete) && (
              <CloseBtn size="small" color="error" onClick={() => data.onDelete(src)}>
                <Close />
              </CloseBtn>
            )}
          </PictureListElement>
        ))}
      </PictureList>
    </Grid>
  );
}
