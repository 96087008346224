import { styled } from "@mui/material"
import PageBody from "component/commons/page/PageBody"
import TextElement from "component/commons/typography/TextElement"
import useTranslate from "hooks/useTranslate"
import React from "react"
import "style/loading.scss"

const CenteredContainer = styled("div")(() => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center"
}))

const loading = {
  EN: "Loading",
  FR: "Chargement"
}

export default function LoadingPage() {
  const translate = useTranslate()
  return (
    <PageBody style={{ position: "relative" }}>
      <CenteredContainer>
        <TextElement Tag="h2">{translate(loading)}&hellip;</TextElement>
        <div className="lds-circle"><div></div></div>
      </CenteredContainer>
    </PageBody>
  );
}